import { downloadFile } from "@/store/services/offerService";
import { EmailMessage } from "@/types";
import { sendMail } from "../task/contactMechanism";


export async function sendMailWithDocument(emailSendList: string[], attachment: any, fileReferences?: any) {
    const attachments:any = []
    
    if (fileReferences) {
      for (const ref of fileReferences){
        if(ref){
            const file = await downloadFile(ref)
            file 
            ? attachments.push(
                    {
                        objectType: "odm.contactmechanism.emailattachment",
                        systemUid: "odm-contactmechanism",
                        resourceUid: null,
                        attachment: {
                        objectType: "contactmechanism-emailattachment",
                            systemUid: "odm-contactmechanism",
                            resourceUid: "signatory-checking", //res.document.resourceUid,
                        },
                        label: file.originalFileName,
                        originalFileName: file.originalFileName,
                        format: file.format,
                        content: file.content
                    }
            )
            :null
        }
      }
    }
      

    attachment 
    ? attachments.push(
            {
                objectType: "odm.contactmechanism.emailattachment",
                systemUid: "odm-contactmechanism",
                resourceUid: "signatory-checking",
                attachment: {
                objectType: "contactmechanism-emailattachment",
                    systemUid: "odm-contactmechanism",
                    resourceUid: "signatory-checking", 
                },
                label: attachment.label,
                originalFileName: attachment.originalFileName,
                format: attachment.format,
                content: attachment.content
            }
    )
    :null

    const body: EmailMessage = {
        objectType: "odm.contactmechanism.communicationrequest.emailmessage",
        clientApplication: {
          systemUid: "odm-contactmechanism",
          objectType: "odm.contactmechanism.contactmechanismclient",
          resourceUid: "ApplicationClient",
        },
        emailTemplate: {
          objectType: "odm.contactmechanism.emailtemplate",
          systemUid: "odm-contactmechanism",
          resourceUid: "SendNoticeTemplate",
        },
        parameters: {
          signatory_name: emailSendList.join(),
          signatory_email: emailSendList.join().replace(",", ";"),
        },
        businessData: null,
        relatedObjects: null,
        attachments
    };

    await sendMail(body)
    
}
