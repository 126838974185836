import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-609f78e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tw-document-wrapper z-top controls-background" }
const _hoisted_3 = { class: "card-header bg-white d-flex justify-content-between align-items-center pl-3 pr-2" }
const _hoisted_4 = { class: "card-title mb-0 d-flex align-items-center" }
const _hoisted_5 = { class: "font-weight-bold" }
const _hoisted_6 = { class: "card-title mb-0 d-flex align-items-end" }
const _hoisted_7 = { class: "row card-body tw-document-container" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_PrintDocumentList = _resolveComponent("PrintDocumentList")!
  const _component_PrintDocumentPreview = _resolveComponent("PrintDocumentPreview")!
  const _component_PrintDocumentSender = _resolveComponent("PrintDocumentSender")!
  const _component_PrintDocumentConfirmationDialog = _resolveComponent("PrintDocumentConfirmationDialog")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(`${_ctx.$t("printDocument.reference")}: ${_ctx.reference}`), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_icon, {
                  name: "chevron_right",
                  size: "20px",
                  onClick: _ctx.toggleShowControls
                }, null, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_PrintDocumentList, {
                      class: "q-mr-md",
                      reference: _ctx.reference
                    }, null, 8, ["reference"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_PrintDocumentPreview)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                (_ctx.showSendTemplate)
                  ? (_openBlock(), _createBlock(_component_PrintDocumentSender, { key: 0 }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_PrintDocumentConfirmationDialog, {
              show: _ctx.dialogState && _ctx.show
            }, null, 8, ["show"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}