
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import { PDFDocument } from "pdf-lib";

@Options({
  props: ["reference"],
})
export default class PrintDocumentActions extends Vue {
  reference: any;
  isDownloadDocumentLoading = false
  isSendDocumentLoading = false


  get selectedTemplates() {
    return (store.state.printDocumentModule.templates || [] ).filter((item) => item.selected)
  }

  get templates() {
    return store.state.printDocumentModule.templates
  }

  onDownloadDocumentClick() {
    this.isDownloadDocumentLoading = true
    this.isSendDocumentLoading = false
    store.dispatch('printDocumentModule/generateDocument', {
      currentRoute: this.currentRoute,
      templatesSelected: this.selectedTemplates,
      from:"PrintDocumentActions",
      callback :(templateName: any)=> this.downloadFile(templateName)
    })
  }

  downloadFile(templateName: any){
    store.dispatch("printDocumentModule/downloadFile", {
      request: {
        resourceUid: this.fileId
      },
       callback: (response: any) => this.downloadDocumentResponse(response,templateName),
    });
  }

  countTemplates:any=0
  templatesNames:any=[]
  downloadDocumentResponses:any=[]

  async downloadDocumentResponse(response: any, templateName: any) {
    this.countTemplates++;
    this.downloadDocumentResponses.push(response);
    this.templatesNames.push(templateName);
    if (this.selectedTemplates.length === this.countTemplates && this.downloadDocumentResponses.length === this.selectedTemplates.length && this.templatesNames.length === this.selectedTemplates.length) {
        await this.mergeAndDownloadPDFs();
        this.countTemplates = 0;
        this.templatesNames = [];
        this.downloadDocumentResponses = [];
    }
  }

  async mergeAndDownloadPDFs() {
      const mergedPdf = await PDFDocument.create();
      for (const response of this.downloadDocumentResponses) {
          const { content } = response;
          const pdfBytes = Uint8Array.from(atob(content), (char) => char.charCodeAt(0));
          const pdf = await PDFDocument.load(pdfBytes);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page));
      }
      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `Editique_${this.reference}.pdf`
      downloadLink.click();
      this.viewDocumentResponse()
  }

  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }

  viewDocumentResponse() {
    this.isDownloadDocumentLoading = false
    this.isSendDocumentLoading = false
  }

  async onSendDocumentClick() {

    this.isSendDocumentLoading = true
    await store.dispatch("printDocumentModule/sendTemplate", {
      template: this.selectedTemplates,
    }).then(() => {
      this.isSendDocumentLoading = true
    });
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

}
