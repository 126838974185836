import { Picklist} from "@/types";

import {getOperator, globalConfig, headers} from "@/utils";
import {settings} from "@/settings";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";
import { api } from "@/auth/api";
import i18n from "@/i18n";
const api_query_url = settings.api_query_url;
const supportingdocument = `odm-supportingdocument`
const baseUrl = `${settings.api_url}/${supportingdocument}/api/1/${supportingdocument}`


export function searchDocumentsType(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "search-supportingdocument-document-type",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
     
      
      if (filter && filter.documentType) {
        operators.push(
            getOperator(
              "lower(supportingdocument_document_type",
              "id)",
              "LIKE",
              globalConfig.formatters
                .getEqualValue("%" + filter.documentType + "%")
                .toLowerCase(),
              "id"
            )
          );
      }

      if (filter && filter.whenCreated && filter.whenCreated.from) {
        operators.push(
          getOperator(
            "supportingdocument_document_type",
            "_when_created",
            "GREATER_THAN_EQUAL",
            globalConfig.formatters.getEqualValue(moment(filter.whenCreated.from,  'DD/MM/YYYY').format('YYYY-MM-DD')),
            "whencreated"
          )
        );
      }
      if (filter && filter.whenCreated && filter.whenCreated.to) {
        operators.push(
          getOperator(
            "supportingdocument_document_type",
            "_when_created",
            "LESS_THAN_EQUAL",
            globalConfig.formatters.getEqualValue(moment(filter.whenCreated.to,  'DD/MM/YYYY').format('YYYY-MM-DD')),
            "whencreated"
          )
        );
      }
     

      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 100;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}

export async function  createDocumentsType(documentType: any) {  
  return api().post(`${baseUrl}/documenttype/`, documentType)
}

export async function  createDocumentsFamily(documentFamily: any) {  
  return api().post(`${baseUrl}/supportingdocumentfamily/`, documentFamily)
}
export async function  getDocumentsFamily(resourceUid: any) {  
  return api().get(`${baseUrl}/supportingdocumentfamily/`+resourceUid+'/')
}

export async function  createAssociation(parentResourceUid:any,documentFamily: any) {  
  return api().post(`${baseUrl}/documenttype/`+parentResourceUid+'/supportingtype/', documentFamily)
}
export async function  updateAssociation(resourceUid:any,documentFamily: any) {  
  return api().put(`${baseUrl}/supportingtype/`+resourceUid+'/', documentFamily)
}
export async function  deleteAssociation(resourceUid:any) {  
  return api().delete(`${baseUrl}/supportingtype/`+resourceUid+'/')
}


export const createAssociationMethode = async (payload: any) => {
  const list=[]
  const documentFamily= await getAssociation(payload.resourceUid)
  if (documentFamily && documentFamily.selectedDocuments){
    for (const doc of documentFamily.selectedDocuments) {
      const docExists = payload.selectedDocuments.some(
        (payloadDoc:any) => payloadDoc.resourceUid === doc.resourceUid
      );
      if (!docExists) {
        await deleteAssociation(doc.config.documenttypeid);
      }
    }
  }
  
  for(const doc of payload.selectedDocuments){
    const body:any={
      proofLevel: 100,
      manual:false ,
      mandatory: doc.isTrue,
      displayBR: doc.displayBR ? {
        rule: JSON.parse(doc.displayBR),
        ruleEngineId: null
      } : null,
      supportingDocument: {
        objectType: "odm.supportingdocument.supportingdocumentfamily",
        systemUid: "odm-supportingdocument",
        resourceUid: payload.resourceUid,
      },
      objectType: "odm.supportingdocument.supportingtype",
      systemUid: "odm-supportingdocument",
      daaq: "/",
      resourceUid: doc.resourceUid
    }
    if (body.resourceUid === ''){
      delete body.resourceUid
    }
    let association
    if(doc.config&&doc.config.documenttypeid){
      body.resourceUid=doc.config.documenttypeid
      association = await updateAssociation(doc.config.documenttypeid,body).then((res: any) => {
        Notify.create({
          message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
          color: 'positive'
        })
      }).catch(err => {
            Notify.create({
                  message: "erreur",
                  color: 'negative'
                })
              }) 
       
    }else{
     association = await createAssociation(doc.value,body).then((res: any) => {
      Notify.create({
        message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
        color: 'positive'
      })
    }).catch(err => {
          Notify.create({
                message: "erreur",
                color: 'negative'
              })
            }) 
    }
    
    list.push(association)
  }
  const documentFamilyEdit= await getAssociation(payload.resourceUid)
  return documentFamilyEdit

}
export async function  getAssociation(id: any) {  
  const filter={
    documentfamilyid:id
  }
  const documentFamilyList:any =await searchDocumentFamily(filter)
  if(documentFamilyList&&documentFamilyList.length>0){
    const listDoc=[]
    for(const documentFamily of documentFamilyList){
      if(documentFamily.document_type_id){
      listDoc.push({value:documentFamily.document_type_id,
        label:documentFamily.document_type_id ,
        isTrue:documentFamily.mandatory,
        displayBR:documentFamily.displaybr,
        resourceUid:documentFamily.documenttypeid,
        config:documentFamily
      })
    }
    }
    const documentFamilyEdit= {
      objectType: "odm.supportingdocument.supportingdocumentfamily",
    systemUid: "odm-supportingdocument",
    resourceUid: id,
    reference:id,
    daaq:"/",
    isMandatory:documentFamilyList[0].mandatory,
    selectedDocuments:listDoc
    }
    return documentFamilyEdit
  }
  
}



export function searchDocumentFamilyList(filter: any): Promise<Picklist> {
  return new Promise<any>(async (resolve, reject) => {
    const url = settings.api_query_url;
    const qc_header: any = {
      qc: {
        queryId: "search-supportingdocument-document-family-list",
        otherFilters: null,
        offset : 0,
        limit : 500
      },
    };
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators:any = [];
    
    if (filter && filter?.documentfamilyid) {
      operators.push(getOperator("lower(supportingdocument_supporting_document_family", "id)", "LIKE", globalConfig.formatters.getEqualValue('%' +filter?.documentfamilyid + '%').toLowerCase(), ""))
    }

    if (filter && filter?.documenttypeid) {
      operators.push(getOperator("lower(supportingdocument_supporting_type", "id)", "LIKE", globalConfig.formatters.getEqualValue('%' +filter?.documenttypeid + '%').toLowerCase(), ""))
    }

    if (filter && filter?.document_type_id) {
      operators.push(getOperator("lower(supportingdocument_supporting_type", "document_type_id)", "LIKE", globalConfig.formatters.getEqualValue('%' +filter?.document_type_id + '%').toLowerCase(), ""))
    }
  
  
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        const response : any[] =[]
        for (const resItem of res.data){
          let accessory = ''
          if (resItem.document_type_id) {
            
            const accessories =  JSON.parse(resItem.document_type_id);
            accessory = accessories.length ===1 && accessories[0] === null 
            ? '' 
            : accessories.filter((item:any) => item !== null).join(', ');
        } 
        response.push({
              documentfamilyid: resItem.documentfamilyid,
              document_type_id: accessory            
        })
        }
        resolve(response);
      })
      .catch((err) => {
          Notify.create({
            message: `Query returned an error \n ${err}`,
            color: "negative",
          });
        });
  });
}

export function searchDocumentFamily(filter: any): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const url = settings.api_query_url;
    const qc_header: any = {
      qc: {
        queryId: "search-supportingdocument-document-family",
      },
    };
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators:any = [];
    
    if (filter && filter?.documentfamilyid) {
      operators.push(getOperator("lower(supportingdocument_supporting_document_family", "id)", "LIKE", globalConfig.formatters.getEqualValue('%' +filter?.documentfamilyid + '%').toLowerCase(), ""))
    }

    if (filter && filter?.documenttypeid) {
      operators.push(getOperator("lower(supportingdocument_supporting_type", "id)", "LIKE", globalConfig.formatters.getEqualValue('%' +filter?.documenttypeid + '%').toLowerCase(), ""))
    }

    if (filter && filter?.document_type_id) {
      operators.push(getOperator("lower(supportingdocument_supporting_type", "document_type_id)", "LIKE", globalConfig.formatters.getEqualValue('%' +filter?.document_type_id + '%').toLowerCase(), ""))
    }
    

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 100;
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
          Notify.create({
            message: `Query returned an error \n ${err}`,
            color: "negative",
          });
        });
  });
}
