
import {Options, Vue} from "vue-class-component";


@Options({
  props: ["orderItems","orderClient"],
  components: {
  },
})
export default class summaryTabOrderTradeIn extends Vue {
   orderItems?: Array<any>;
   orderClient?: any;
    getAssets(orderItem: any) {
    let assetInfo = {}
    const asset = orderItem && orderItem.orderAssets && orderItem.orderAssets.length
            ? orderItem.orderAssets.filter((asset:any)=> asset.assetTradeIn && (asset.assetTradeIn.origin ==="" || asset.assetTradeIn.origin !=="") )[0]
            : null;
    const brand = asset.brand ? asset.brand.replace(/asset.good.vehicle.brand./g, "")   : "-"
    const range = asset.range && asset.range?.includes('range.') ? asset.range.substring(asset.range.lastIndexOf(".") + 1) : asset.range ? asset.range : "-" ;
    const version = asset.version && asset.version?.includes('range.') ? asset.version.substring(asset.version.lastIndexOf("range") + 6) : asset.version ? asset.version  : "-" ;
    const color = asset.color ? asset.color : "-"
    const chassisReference = asset.chassisReference ? asset.chassisReference : "-"
    const engineType = asset.engineType ? asset.engineType.replace(/asset.good.vehicle.energytype./g, "")  : "-"
    const matriculation = asset.matriculation ? asset.matriculation : "-"   
    const matriculationDate = asset.matriculationDate ? asset.matriculationDate : "-"   
    const currentMileage = asset.currentMileage ? asset.currentMileage : "-"
    assetInfo = {
      "brand": brand+" "+range+" "+version,
       "color":color,
        "chassisReference": chassisReference,
        "engineType": engineType,
        "matriculation": matriculation,
        "matriculationDate": matriculationDate,
        "currentMileage": currentMileage
      }
   return assetInfo 
  }  
   get client (){
      return this.orderClient  && this.orderClient.orderParty && this.orderClient.orderParty.party && this.orderClient.orderParty.party.rawData && this.orderClient.orderParty.party.rawData.responseData
                   ? this.orderClient.orderParty.party.rawData.responseData
                   : null
   }
   getClientName(orderItem: any) {
    const asset = orderItem && orderItem.orderAssets && orderItem.orderAssets.length
            ? orderItem.orderAssets.filter((asset:any)=> asset.assetTradeIn && (asset.assetTradeIn.origin ==="" || asset.assetTradeIn.origin !=="") )[0]
            : null;
    const  clientType  = this.orderClient  && this.orderClient.orderParty && this.orderClient.orderParty.party && this.orderClient.orderParty.party.objectType
                   ? this.orderClient.orderParty.party.objectType
                   : "-"
    return asset && asset.resourceUid && clientType === "odm.party.party.person.frenchperson" 
            ? `${this.client.firstName} ${this.client.familyName}`
            : asset && asset.resourceUid && clientType === "odm.party.party.organization.frenchorganization"
            ?  `${this.client.name}`
            : "-"
  }
}
