import { Paths, UON, deepClone, getOperator, globalConfig, headers, operator, setOtherFilters } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import { Picklist, QcHeader } from "@/types";
import { useApi } from "@/requests/useApi";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import { getInOperator } from "../picklist";
import i18n from "@/i18n";
import moment from "moment";

const odm_user = `odm-user`

export function searchAbsenceList(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
      const qc_header: any = {
        qc: {
          queryId: "absence-list-search",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
      
      if (filter && filter?.canalNode && filter?.canalNode?.id) {
        operators.push(getOperator("lower(user_absence", "_daaq)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter?.canalNode?.id + '%').toLowerCase(), ""))
      }

      if (filter && filter?.vendeurList && filter?.vendeurList?.id) {
        operators.push(getOperator("lower(user_absence", "user_code)", "LIKE", globalConfig.formatters.getEqualValue(filter?.vendeurList?.id).toLowerCase(), ""))
      }

      if (filter && filter?.reason_code) {
        operators.push(getOperator("lower(user_absence", "reason_code)", "LIKE", globalConfig.formatters.getEqualValue(filter?.reason_code + '%').toLowerCase(), ""))
      }
      if (filter && filter.startAbsenceDate) {
        operators.push(
          getOperator(
            "user_absence",
            "start_absence_date",
            "GREATER_THAN_EQUAL",
            //formatDateLocale(filter.startAbsenceDate, "DD/MM/YYYY", "fr").toString,
            globalConfig.formatters.getEqualValue(moment(filter.startAbsenceDate,  'DD/MM/YYYY').format('YYYY-MM-DD')),
            "startAbsenceDate"
          )
        );
      }
      if (filter && filter.endAbsenceDate) {
        operators.push(
          getOperator(
            "user_absence",
            "end_absence_date",
            "LESS_THAN_EQUAL",
            //formatDateLocale(filter.endAbsenceDate, "DD/MM/YYYY", "fr").toString,
            globalConfig.formatters.getEqualValue(moment(filter.endAbsenceDate,  'DD/MM/YYYY').format('YYYY-MM-DD')),
            "endAbsenceDate"
          )
        );
      }

      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 50;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
  }

export const saveAbsence = async (absence:any) => {
    const payload = deepClone(absence)
    payload.startAbsenceDate=  payload.startAbsenceDate ? formatDateLocale(payload.startAbsenceDate, 'DD/MM/YYYY', 'fr','YYYY-MM-DD HH:mm:ss'): ''
    payload.endAbsenceDate=  payload.endAbsenceDate ? formatDateLocale(payload.endAbsenceDate, 'DD/MM/YYYY', 'fr', 'YYYY-MM-DD HH:mm:ss'): ''
    payload.user = payload.user_code?.id ? payload.user_code?.id : null
    payload.reason = payload.reason_code?.value ? payload.reason_code?.value : null
    delete payload.reason_code;
    delete payload.user_code;
    delete payload.comment
    const createdAbsence =await createAbsence(payload)
    return createdAbsence
}

export const handleGetAbsence = async (resourceUid:any) => {
  const orderPayPlanObjective = await getAbsence(resourceUid)
  orderPayPlanObjective.reason_code = {
    value: orderPayPlanObjective.reason.resourceUid,
    label: i18n.global.t(`users.absenceReasons.${orderPayPlanObjective.reason.resourceUid}`),
  } 
  delete orderPayPlanObjective.reason
  const user = await getUser(orderPayPlanObjective.user.resourceUid)
  orderPayPlanObjective.user_code = {
    id: orderPayPlanObjective.user.resourceUid,
    label: user.firstName ? user.firstName + " " + user.lastName : user.lastName,
  }
  orderPayPlanObjective.startAbsenceDate = formatDateLocale(orderPayPlanObjective.startAbsenceDate, 'YYYY-MM-DD', 'fr', 'DD/MM/YYYY')
  orderPayPlanObjective.endAbsenceDate = formatDateLocale(orderPayPlanObjective.endAbsenceDate, 'YYYY-MM-DD', 'fr', 'DD/MM/YYYY')
  delete orderPayPlanObjective.user
  return orderPayPlanObjective;
}


export const createAbsence = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmUser, `${Paths.AbsenceManagement.create}`).upsert(payload)).response
  }
  export const getAbsence = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmUser, `${Paths.AbsenceManagement.get(resourceUid)}`).get()).response
}
export async function getUser(userResourceUid: string) {
  const result = await api().get(`${settings.api_url}/${odm_user}/api/1/${odm_user}/leaseforgeuser/${userResourceUid}/`);
  return result.data
}

export const deleteAbsence = async (resourceUid: any) => {
  return (await useApi(UON.SystemUid.odmUser, `absence`).remove(resourceUid)).response
}