import { api } from "@/auth/api";
import { settings } from "@/settings";
import { filter } from "@/types";
import { globalConfig, headers,operator, setOtherFilters } from "@/utils";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import axios from "axios";
import { getInOperator } from "../picklist";
import {getTaskCountByUser} from "@/store/services/task/workflow";
import moment from "moment";
import store from "@/store";

export async function getSavedFilters(queryId: any) {

    const listNotFiltered = await listOfFilters(queryId)
    listNotFiltered.sort((a: filter, b: filter) => a.numOrder - b.numOrder);
    for (const filter of listNotFiltered) {
        if (filter.businessData && filter.businessData.queryId) {
          const data: any = await searchCount(filter)
          filter.count = data.count
        } else if(filter.screenId === 'search-task'){

            const filterCamunda: any = {...filter}

            delete filterCamunda.screenId
            delete filterCamunda.description
            delete filterCamunda.color
            delete filterCamunda.hasPublicAccess
            delete filterCamunda.numOrder

            filterCamunda.initializeVariables = filter.businessData.initializeVariables
            filterCamunda.candidateGroups = filter.businessData.candidateGroups
            filterCamunda.searchVariables = filter.businessData.searchVariables
            filterCamunda.taskDefinitionKeyIn = filter.businessData.taskDefinitionKeyIn
            filterCamunda.includeAssignedTasks = filter.businessData.includeAssignedTasks

            const data: any = await getTaskCountByUser(filterCamunda)
            filter.count = data.data.count
        } else {
          filter.count = 0
        }
    }
    return listNotFiltered
}

async function listOfFilters(queryId: any) {
  const systemUid = "odm-configuration";
  return (
    await api().get(
      `/${systemUid}/api/1/${systemUid}/filterstore/screenid/${queryId}/`
    )
  ).data;
}

export const saveFilter = async (payload: any) => {
  const systemUid = "odm-configuration";
  delete payload.count;
  return (
    await api().post(`/${systemUid}/api/1/${systemUid}/filterstore/`, payload)
  ).data;
};


export const updateFilter = async (payload: any) => {
  const systemUid = 'odm-configuration'
  delete payload.count
  return (await api().put(`/${systemUid}/api/1/${systemUid}/filterstore/` + payload.resourceUid + '/', payload)).data
}

export function getOperator(
  table: string,
  field: string,
  operator: string,
  value?: any,
  parameter?: string,
  inParameters?: string[]
) {
  return {
    expression: {
      table: table,
      field: field,
      operator: operator,
      value: value,
      parameter: parameter,
      inValue:
        inParameters && inParameters.length > 0
          ? {
              values: inParameters,
            }
          : null,
    },
  };
}

export function getQcUser(filter: any , offset?: any, limit?: any) {

  const operators = [];

  const qc_header: any = {
    qc: {
      queryId: "user-lease-forge-user-with-usergroup",
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter,
      },
    },
  };

  const LEASE_FORGE_USER = "user_lease_forge_user";
  const USER_GROUP = "userGroups";
  const PARTY_PARTY_ROLE_NETWORK = "userRoles"

  if (filter?.username) {
    operators.push(
      operator({
        val: filter.username,
        table: LEASE_FORGE_USER,
        dbField: "username",
      }).LIKE
    );
  }

  if (filter?.firstName) {
    operators.push(
      operator({
        val: filter.firstName,
        table: LEASE_FORGE_USER,
        dbField: "first_name",
      }).LIKE
    );
  }

  if (filter?.lastName) {
    operators.push(
      operator({
        val: filter.lastName,
        table: LEASE_FORGE_USER,
        dbField: "last_name",
      }).LIKE
    );
  }


  if (filter?.email) {
    operators.push(
      operator({
        val: filter.email,
        table: LEASE_FORGE_USER,
        dbField: "email",
      }).LIKE
    );
  }

  if (filter?.status) {
    operators.push(
      operator({
        val: filter.status,
        table: LEASE_FORGE_USER,
        dbField: "enabled",
      }).EQUAL
    );
  }

  if (filter?.notExpired) {
    operators.push(
      operator({
        val: filter.notExpired,
        table: LEASE_FORGE_USER,
        dbField: "account_not_expired",
      }).EQUAL
    );
  }

  if (filter?.notLocked) {
    operators.push(
      operator({
        val: filter.notLocked,
        table: LEASE_FORGE_USER,
        dbField: "account_not_locked",
      }).EQUAL
    );
  }

  if (filter?.credintialsNotExpired) {
    operators.push(
      operator({
        val: filter.credintialsNotExpired,
        table: LEASE_FORGE_USER,
        dbField: "credentials_not_expired",
      }).EQUAL
    );
  }
  
  if (filter?.userGroups) {
    /*operators.push(
      operator({
        val: filter.userGroups,
        table: USER_GROUP,
        dbField: "group_code::text",
      }).LIKE
    );*/
    operators.push(
      getOperator(
        "cast(userGroups",
        "group_code  AS text)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.userGroups),
        "group_code"
    )
  );
  }

  if (filter?.networkNodes) {
    operators.push(
      operator({
        val: filter.networkNodes,
        table: PARTY_PARTY_ROLE_NETWORK,
        dbField: "network_node_id",
      }).LIKE
    );
  }

  setOtherFilters(operators, qc_header);

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
  }
  return qc_header.qc;

}


export function getQcGaranty(filter : any , offset? : any , limit? : any) {
  const operators = [];

  const qc_header: any = {
    qc: {
      queryId: "product-product-feature",
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter,
      },
    },
  };

  const PRODUCT_FEATURE = "product_product_feature";

  if (filter?.reference) {
    operators.push(
      operator({
        val: filter.reference,
        table: PRODUCT_FEATURE,
        dbField: "_reference",
      }).LIKE
    );
  }


  if (filter?.type) {

    operators.push(
      operator({
        val: filter.type,
        table: PRODUCT_FEATURE,
        dbField: "_discriminator",
      }).LIKE
    );
  }

  if (filter?.guaranty_name) {
    operators.push(
      operator({
        val: filter.guaranty_name,
        table: PRODUCT_FEATURE,
        dbField: "_name",
      }).LIKE
    );
  }

  if (filter?.status) {
    operators.push(
      operator({
        val: filter.status,
        table: PRODUCT_FEATURE,
        dbField: "status_code",
      }).LIKE
    );
  }

  setOtherFilters(operators, qc_header);

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
  }
  return qc_header.qc;

}

export function getQcGood(filter: any , offset?: any , limit?: any) {
    const qc_header: any = {
      qc: {
        queryId: 'asset-search-filter',
        offset: offset || 0,
        limit: limit || 100
      }
    }

    qc_header.qc.parameters = {
      ...filter
    }
    const operators = []


    if (filter.reference) {
      operators.push(getOperator("lower(product_product_feature", "_reference)", "LIKE", globalConfig.formatters.getEqualValue(filter.reference + '%').toLowerCase(), "_reference"))
    }
    if (filter.name) {
      operators.push(getOperator("lower(product_product_feature", "_name)", "LIKE", globalConfig.formatters.getEqualValue(filter.name + '%').toLowerCase(), "name"))
    }
    if (filter.brand) {
      operators.push(getOperator("lower(ppc_brand", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.brand.label + '%').toLowerCase(), "id"))
    }
    if (filter.model) {
      operators.push(getOperator("lower(ppc_model", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.model.label + '%').toLowerCase(), "id"))
    }
    if (filter.status) {
      operators.push(getOperator("product_product_feature", "status_code", "EQUAL", globalConfig.formatters.getEqualValue(filter.status), "status"))
    }
    if (filter.family) {
      operators.push(getOperator("lower(ppc_famille", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.family.id + '%').toLowerCase(), "id"))
    }
    if (filter.catalog) {
      operators.push(getOperator("lower(product_product_catalog", "_reference)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.catalog), "_reference"))
    }
    if (filter.supplier && filter.supplier.id) {
      operators.push(getOperator("product_product_catalog", "provider_resourceuid", "LIKE", globalConfig.formatters.getEqualValue(filter.supplier.id.resourceUid), "provider_resourceuid"))
    }
    if (filter.inventory) {
      operators.push(getOperator("product_inventory_item", "asset_product_id", "ISNOTNULL"));
      }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
    }
    return qc_header.qc;

}

export function getQcScale(filter: any, offset?: any , limit?: any) {
  const operators = [];

    const qc_header: any = {
        qc: {
            queryId: 'scales-search-filter',
            offset: offset || 0,
            limit: limit || 100,
            parameters: {
                ...filter
            }
        }
    };

    const PRODUCT_FEATURE = "product_product_feature";
    const PRODUCT_CLASSIFICATION = "product_product_classification";

    if (filter?.name) {
        operators.push(
            operator(
                { val: filter.name, table: PRODUCT_FEATURE, dbField: "_name" }
            ).LIKE
        );
    }

    if (filter?.reference) {
        operators.push(
            operator(
                { val: filter.reference, table: PRODUCT_FEATURE, dbField: "_reference" }
            ).LIKE
        );
    }

    if (filter?.validity?.from && filter?.validity?.from !== '') {
        operators.push(
            operator(
                { val: filter.validity.from, table: PRODUCT_FEATURE, dbField: "validity_from" }
            ).GREATER_THAN_EQUAL
        );
    }

    if (filter?.validity?.until && filter?.validity?.until !== '') {
        operators.push(
            operator(
                { val: filter.validity.until, table: PRODUCT_FEATURE, dbField: "validity_until" }
            ).LESS_THAN_EQUAL
        );
    }

    if (filter?.financialProfile) {
        operators.push(
            operator(
                { val: filter.financialProfile, table: PRODUCT_FEATURE, dbField: "financial_profile_code" }
            ).EQUAL
        );
    }

    if (filter?.status) {
        operators.push(
            operator(
                { val: filter.status, table: PRODUCT_FEATURE, dbField: "status_code" }
            ).EQUAL);
    }

    /**
     * Checks if the array contains any value that is not undefined.
     *
     * @template T
     * @param {T[]} arr - The array to check.
     * @return {boolean} True if the array contains any non-undefined value, false otherwise.
     */
    const notOnlyUndefined = <T>(arr: T[]): boolean => arr.some(item => item !== undefined);

    /**
     * Removes any undefined values from an array.
     *
     * @template T
     * @param {T[]} arr - The array to remove undefined values from.
     * @return {T[]} The array without any undefined values.
     */
    const removeTheUndefined = <T>(arr: T[]): T[] => arr.filter(item => item !== undefined);

    if (filter?.category && filter.category.length > 0 && notOnlyUndefined(filter.category)) {
        operators.push(
            operator(
                {
                    val: removeTheUndefined(filter.category),
                    table: PRODUCT_CLASSIFICATION,
                    dbField: "category_code"
                }
            ).IN
        );
    }

    if (filter?.class && filter.class.length > 0 && notOnlyUndefined(filter.class)) {
        operators.push(
            operator(
                {
                    val: removeTheUndefined(filter.class),
                    table: PRODUCT_CLASSIFICATION,
                    dbField: "category_class_code"
                }
            ).IN
        );
    }

    setOtherFilters(operators, qc_header);

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }
    return qc_header.qc;
}

export function getQcAvailability(filter: any, offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: 'product-availability-filter-search',
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter
      }
    }
  };

  const operators = [];
  const PRODUCT_AVAILABILITY = "product_product_availability";

  if (filter?.name) {
    operators.push(
      operator(
        { val: filter.name, table: PRODUCT_AVAILABILITY, dbField: "application_name" }
      ).LIKE
    );
  }

  if (filter?.validity?.from && filter?.validity?.from !== '') {
    operators.push(
      operator(
        { val: filter.validity.from, table: PRODUCT_AVAILABILITY, dbField: "validity_from" }
      ).GREATER_THAN_EQUAL
    );
  }

  if (filter?.validity?.until && filter?.validity?.until !== '') {
    operators.push(
      operator(
        { val: filter.validity.until, table: PRODUCT_AVAILABILITY, dbField: "validity_until" }
      ).LESS_THAN_EQUAL
    );
  }

  if (filter?.context) {
    operators.push(
      operator(
        { val: filter.context, table: PRODUCT_AVAILABILITY, dbField: "context" }
      ).LIKE
    );
  }

  // if (filter?.context !== undefined) {
  //     operators.push(getOperator(PRODUCT_AVAILABILITY, "context", "EQUAL", globalConfig.formatters.getEqualValue(filter.context ), "context"))
  // }

  setOtherFilters(operators, qc_header);
  
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;

}

export function getQcOrder(filter: any,offset?: any, limit?: any) {
  let qc_header: any
  if (filter.orderConditionsList&& filter.orderConditionsList.length > 0) {
       qc_header  = {
        qc: {
          queryId: "order-search-filter-backup2",
          offset: offset || 0,
          limit: limit || 100,
        },
      };
    }
    else {
      qc_header  = {
        qc: {
          queryId: "order-search-filter-backup",
          offset: offset || 0,
          limit: limit || 100,
        },
      };
    }
      qc_header.qc.parameters = {
        ...filter,
      };
  const operators = [];
  const operatorsName = [];
  const connectedUserDAAQ = store.state.authModule?.user 
            && store.state.authModule?.user.daaqList 
            && store.state.authModule?.user.daaqList[0] 
            && store.state.authModule?.user.daaqList[0].config
            && store.state.authModule?.user.daaqList[0].config.createDAAQ
            ? store.state.authModule?.user.daaqList[0].config.createDAAQ
            : "/"
      operators.push(
        getOperator(
          "order_order",
          "_daaq",
          "LIKE",
          globalConfig.formatters
          .getEqualValue(connectedUserDAAQ + "%"),
          "_daaq"
        )
      );
  if(filter.planingEvent){
    // operators.push(getInOperator("order_order", "status_code", "IN", null, null, globalConfig.formatters.getInValue(['DELIVERED','PLANNED','TO_PLAN','TO_BE_RESCHEDULED'])))
    operators.push(getOperator("order_order_item", "external_reference", "ISNOTNULL"));
  }

  if (filter.filterReference) {
    operators.push(
      getOperator(
        "lower(order_order",
        "_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.filterReference + "%")
          .toLowerCase(),
        "_reference"
      )
    );
  }
  if (filter.externalReference) {
    operators.push(
      getOperator(
        "lower(order_order_item",
        "external_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.externalReference + "%")
          .toLowerCase(),
        "external_reference"
      )
    );
  }
  if (filter.filterStatus) {
    operators.push(
      getOperator(
        "order_order",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.filterStatus),
        "status_code"
      )
    );
  }
  if (filter.manufacturingNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "num_fabrication)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.manufacturingNumber + "%")
          .toLowerCase(),
        "num_fabrication"
      )
    );
  }
  if (filter.registrationNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "matriculation)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.registrationNumber + "%")
          .toLowerCase(),
        "matriculation"
      )
    );
  }
  if (filter.activity !== undefined) {
    operators.push(
      getOperator(
        "order_order_asset",
        "flag_new",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.activity),
        "flag_new"
      )
    );
  }
  if (filter.delivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNOTNULL")
    );
  }
  if (filter.notDelivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNULL")
    );
  }
  if (filter.brand) {
    /*operators.push(
      getOperator(
        "order_order_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand),
        "brand"
      )
    );*/
      const brandModel = filter.brand.split('asset.good.vehicle.brand.')[1] ? filter.brand.split('asset.good.vehicle.brand.')[1] : undefined;
      const brandWithout_ = brandModel.replace("_", " "); 
      const filteredBrandArray =[filter.brand,brandModel,brandWithout_].filter(item => item !== undefined);
     
      operators.push(
          operator(
              {
                  val: filteredBrandArray,
                  table: "trim(order_order_asset",
                  dbField: "brand)"
              }
          ).IN
      );
  //}
  
  }
  if (filter.model) {
    /*operators.push(
      getOperator(
        "order_order_asset",
        "range",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.model),
        "range"
      )
    );*/
    //row.ranges && row.ranges.includes('range.') ? row.ranges.substring(row.ranges.lastIndexOf(".") + 1)
    const range = filter.model && filter.model.includes('range.') ? filter.model.substring(filter.model.lastIndexOf(".") + 1) : undefined;
    const rangeWithout_ = range.replace("_", " "); 
      const filteredRangeArray =[filter.model,range,rangeWithout_].filter(item => item !== undefined);
    operators.push(
        operator(
            {
                val: filteredRangeArray,
                table: "trim(order_order_asset",
                dbField: "_range)"
            }
        ).IN
    );
  }
  if (filter.chassisReference) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "chassis_reference)",
        "LIKE",
        globalConfig.formatters
        .getEqualValue("%" + filter.chassisReference + "%")
        .toLowerCase(),
        "chassis_reference"
      )
    );
  }
  if (filter.orderAlertsList&& filter.orderAlertsList.length > 0) {
    operators.push(
      getInOperator(
        "order_order_alert_reason",
        "reason_code",
        "IN",
        null,
        null,
        globalConfig.formatters.getInValue(filter.orderAlertsList)
      )
    );
  }
  if (filter.orderConditionsList&& filter.orderConditionsList.length > 0) {
    operators.push(
      getInOperator(
        "order_condition",
        "condition_code",
        "IN",
        null,
        null,
        globalConfig.formatters.getInValue(filter.orderConditionsList)
      )
    );
    operators.push(
      getOperator(
        "order_condition",
        "checked",
        "EQUAL",
        globalConfig.formatters.getEqualValue('true'),
        "checked"
      )
    );
  }

  if (filter.contractStatus) {
    operators.push(
      getOperator(
        "order_order_item",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.contractStatus),
        "status_code"
      )
    );
  }

  if (filter.deliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order_item",
        "initial_delivery_date",
        "GREATER_THAN_EQUAL",
        //formatDateLocale(filter.deliveryDateFrom, "DD/MM/YYYY", "fr").toString,
        globalConfig.formatters.getEqualValue(moment(filter.deliveryDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "deliveryDateFrom"
      )
    );
  }
  if (filter.deliveryDateTo) {
    operators.push(
      getOperator(
        "order_order_item",
        "initial_delivery_date",
        "LESS_THAN_EQUAL",
        //formatDateLocale(filter.deliveryDateTo, "DD/MM/YYYY", "fr").toString,
        globalConfig.formatters.getEqualValue(moment(filter.deliveryDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "deliveryDateTo"
      )
    );
  }
  if (filter.deliveryDateToggle !== undefined && filter.deliveryDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_item",
        "initial_delivery_date",
        filter.deliveryDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.previsonalDeliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order_item",
        "prevision_delivery_date",
        "GREATER_THAN_EQUAL",
        //formatDateLocale(filter.previsonalDeliveryDateFrom, "DD/MM/YYYY", "fr")
         // .toString,
         globalConfig.formatters.getEqualValue(moment(filter.previsonalDeliveryDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "previsonalDeliveryDateFrom"
      )
    );
  }
  if (filter.previsonalDeliveryDateTo) {
    operators.push(
      getOperator(
        "order_order_item",
        "prevision_delivery_date",
        "LESS_THAN_EQUAL",
        //formatDateLocale(filter.previsonalDeliveryDateTo, "DD/MM/YYYY", "fr")
         // .toString,
         globalConfig.formatters.getEqualValue(moment(filter.previsonalDeliveryDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "previsonalDeliveryDateTo"
      )
    );
  }
  if (filter.previsonalDeliveryDateToggle !== undefined && filter.previsonalDeliveryDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_item",
        "prevision_delivery_date",
        filter.previsonalDeliveryDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.effectiveDeliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order_item",
        "effective_delivery_date",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.effectiveDeliveryDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "effectiveDeliveryDateFrom"
      )
    );
  }
  if (filter.effectiveDeliveryDateTo) {
    operators.push(
      getOperator(
        "order_order_item",
        "effective_delivery_date",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.effectiveDeliveryDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "effectiveDeliveryDateTo"
      )
    );
  }
  if (filter.effectiveDeliveryDateToggle !== undefined && filter.effectiveDeliveryDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_item",
        "effective_delivery_date",
        filter.effectiveDeliveryDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.effectiveDeliveryFactoryDateFrom) {
    operators.push(
      getOperator(
        "order_order_asset",
        "effective_delivery_factory_date",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.effectiveDeliveryFactoryDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "effectiveDeliveryFactoryDateFrom"
      )
    );
  }
  if (filter.effectiveDeliveryFactoryDateTo) {
    operators.push(
      getOperator(
        "order_order_asset",
        "effective_delivery_factory_date",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.effectiveDeliveryFactoryDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "effectiveDeliveryFactoryDateTo"
      )
    );
  }
  if (filter.effectiveDeliveryFactoryDateToggle !== undefined && filter.effectiveDeliveryFactoryDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_asset",
        "effective_delivery_factory_date",
        filter.effectiveDeliveryFactoryDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.factoryOrderDateFrom) {
    operators.push(
      getOperator(
        "order_order_delivery_details",
        "factory_order_date",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.factoryOrderDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "factoryOrderDateFrom"
      )
    );
  }
  if (filter.factoryOrderDateTo) {
    operators.push(
      getOperator(
        "order_order_delivery_details",
        "factory_order_date",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.factoryOrderDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "factoryOrderDateTo"
      )
    );
  }
  if (filter.factoryOrderDateToggle !== undefined && filter.factoryOrderDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_delivery_details",
        "factory_order_date",
        filter.factoryOrderDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.customerAppointmentDateFrom) {
    operators.push(
      getOperator(
        "order_order_item",
        "customer_appointment_date",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.customerAppointmentDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "customerAppointmentDateFrom"
      )
    );
  }
  if (filter.customerAppointmentDateTo) {
    operators.push(
      getOperator(
        "order_order_item",
        "customer_appointment_date",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.customerAppointmentDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "customerAppointmentDateTo"
      )
    );
  }
  if (filter.customerAppointmentDateToggle !== undefined && filter.customerAppointmentDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_item",
        "customer_appointment_date",
        filter.customerAppointmentDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.supplierName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.supplierName + "%")
          .toLowerCase(),
        "_name"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.clientReference + "%")
          .toLowerCase(),
        "_reference"
      )
    );
  }
  if (filter.family_name) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "family_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%"+ filter.family_name + "%")
          .toLowerCase(),
        "family_name"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "first_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%"+ filter.firstName + "%")
          .toLowerCase(),
        "first_name"
      )
    );
  }
  if (filter.commercial_name) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%"+ filter.commercial_name + "%")
          .toLowerCase(),
        "commercial_name"
      )
    );
  }
    if (filter.name) {
      const filterValue = globalConfig.formatters.getEqualValue("%" + filter.name + "%").toLowerCase();
      operatorsName.push(
          getOperator(
          "lower(party_party_1",
          "family_name)",
          "LIKE",
          filterValue,
          "family_name"
        )
      );
      operatorsName.push(
        getOperator(
          "lower(party_party_1",
          "first_name)",
          "LIKE",
          filterValue,
          "first_name"
        )
      );
      operatorsName.push(
        getOperator(
          "lower(party_party_1",
          "commercial_name)",
          "LIKE",
          filterValue,
          "commercial_name"
        )
      );
      operatorsName.push(
        getOperator(
          "lower(party_party_1",
          "_name)",
          "LIKE",
          filterValue,
          "_name"
        )
      );
    }
  /*if (filter.role_code && filter.role_code.length > 0) {
    operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.role_code),
            "role_code"
        )
    );
  }*/ 
  if (filter.partyRole && filter.partyRole.length > 0) {
    /*operators.push(
        getOperator(
            "oop_customer",
            "role_code",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.partyRole),
            "role_code"
        )
    );*/
  let  role = filter.partyRole.toLowerCase()
    operators.push(
      getOperator(
          "lower(orderpartyroles",
           role+")",
          "EQUAL",
          globalConfig.formatters.getEqualValue(filter.partyRole)
          .toLowerCase(),
          role
      )
  );
  } 
  if (filter.preconditionsStatus) {
    filter.preconditionsStatus === 'PENDING'
    ?
    operators.push(
      getOperator("supportingdocument_received_file", "status_code", "ISNULL")
    )
    :
    operators.push(
      getOperator(
        "supportingdocument_received_file",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.preconditionsStatus),
        "status_code"
    )
    );
  }
  if (filter.financingType) {
    operators.push(
      getOperator(
        "order_order_financing",
        "label",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.financingType),
        "label"
      )
    );
  }
  if (filter.preconditionsList&&filter.preconditionsList.length>0) {
    operators.push(getInOperator("supportingdocument_check_points", "check_points_config_code", "IN", null, null, globalConfig.formatters.getInValue(filter.preconditionsList)))

    /*operators.push(
      getOperator(
        "supportingdocument_check_points",
        "check_points_config_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.preconditionsList),
        "check_points_config_code"
    )
    );*/
  }
  if (filter.documentList&&filter.documentList.length>0) {  
    operators.push(getInOperator("supportingdocument_received_file_content", "document_type_code", "IN", null, null, globalConfig.formatters.getInValue(filter.documentList)))
    /*operators.push(
      getOperator(
        "supportingdocument_received_file_content",
        "document_type_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.documentList),
        "document_type_code"
    )
    );*/
  }
  /*if (filter.preconditionDate) {
    operators.push(
      getOperator(
        "cast(supportingdocument_received_file",
        "reception_date  AS date)",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.preconditionDate),
        "reception_date"
    )
    );
  }*/
  if (filter.preconditionDateFrom) {
    operators.push(
      getOperator(
        "cast(supportingdocument_received_file",
        "reception_date  AS date)",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.preconditionDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "reception_date"
      )
    );
  }
  if (filter.preconditionDateTo) {
    operators.push(
      getOperator(
        "cast(supportingdocument_received_file",
        "reception_date  AS date)",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.preconditionDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "_when_created"
      )
    );
  }
  if (filter.preconditionDateToggle !== undefined && filter.preconditionDateToggle !== null) {
    operators.push(
      getOperator(
        "supportingdocument_received_file",
        "reception_date",
        filter.preconditionDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (filter.initialDeliveryFactoryDateFrom) {
    operators.push(
      getOperator(
        "cast(order_order_asset",
        "initial_delivery_factory_date  AS date)",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.initialDeliveryFactoryDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "initial_delivery_factory_date"
      )
    );
  }
  if (filter.initialDeliveryFactoryDateTo) {
    operators.push(
      getOperator(
        "cast(order_order_asset",
        "initial_delivery_factory_date  AS date)",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.initialDeliveryFactoryDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "initial_delivery_factory_date"
      )
    );
  }
  if (filter.initialDeliveryFactoryDateToggle !== undefined && filter.initialDeliveryFactoryDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_asset",
        "initial_delivery_factory_date",
        filter.initialDeliveryFactoryDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }

  if (filter.availabilityCarDateFrom) {
    operators.push(
      getOperator(
        "cast(order_order_asset",
        "availability_car_date  AS date)",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.availabilityCarDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "availability_car_date"
      )
    );
  }
  if (filter.availabilityCarDateTo) {
    operators.push(
      getOperator(
        "cast(order_order_asset",
        "availability_car_date  AS date)",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.availabilityCarDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "availability_car_date"
      )
    );
  }
  if (filter.availabilityCarDateToggle !== undefined && filter.availabilityCarDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_asset",
        "availability_car_date",
        filter.availabilityCarDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  /*if (filter.immatriculationDate) {
    operators.push(
      getOperator(
        "cast(order_order_asset",
        "matriculation_date  AS date)",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.immatriculationDate),
        "matriculation_date"
    )
    );
  }*/
  if (filter.immatriculationDateFrom) {
    operators.push(
      getOperator(
        "order_order_asset",
        "matriculation_date",
        "GREATER_THAN_EQUAL",
        //formatDateLocale(filter.immatriculationDateFrom, "DD/MM/YYYY", "fr").toString,
        globalConfig.formatters.getEqualValue(moment(filter.immatriculationDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "immatriculationDateFrom"
      )
    );
  }
  if (filter.immatriculationDateTo) {
    operators.push(
      getOperator(
        "order_order_asset",
        "matriculation_date",
        "LESS_THAN_EQUAL",
        //formatDateLocale(filter.immatriculationDateTo, "DD/MM/YYYY", "fr").toString,
        globalConfig.formatters.getEqualValue(moment(filter.immatriculationDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "immatriculationDateTo"
      )
    );
  }
  if (filter.immatriculationDateToggle !== undefined && filter.immatriculationDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order_asset",
        "matriculation_date",
        filter.immatriculationDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  /*if (filter.orderCreatedDate) {
    operators.push(
      getOperator(
        "cast(order_order",
        "_when_created  AS date)",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.orderCreatedDate),
        "_when_created"
    )
    );
  }*/
  if (filter.orderCreatedDateFrom) {
    operators.push(
      getOperator(
        "cast(order_order",
        "_when_created  AS date)",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.orderCreatedDateFrom,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "_when_created"
      )
    );
  }
  if (filter.orderCreatedDateTo) {
    operators.push(
      getOperator(
        "cast(order_order",
        "_when_created  AS date)",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(moment(filter.orderCreatedDateTo,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "_when_created"
      )
    );
  }
  if (filter.orderCreatedDateToggle !== undefined && filter.orderCreatedDateToggle !== null) {
    operators.push(
      getOperator(
        "order_order",
        "_when_created",
        filter.orderCreatedDateToggle === true ? "ISNOTNULL" : "ISNULL"
      )
    );
  }
  if (!filter.tradeInImmatriculation) {
    operators.push(
      getOperator("order_order_asset", "asset_trade_in_id", "ISNULL")
    );
  }
  if (filter.tradeInImmatriculation) {
    operators.push(
      getOperator("order_order_asset", "asset_trade_in_id", "ISNOTNULL")
    );
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "matriculation)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.tradeInImmatriculation + "%")
          .toLowerCase(),
        "matriculation"
      )
    );
  }
  else {
    /*operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            "'CUSTOMER'",
            "role_code"
        )
    );*/
  }

      if ( operatorsName.length> 0 ){
            operators.push( { or: {
              operators :[... operatorsName]
            } 
          })
      }
      
    if (operators.length > 0 ) {
          qc_header.qc.otherFilters = {
            expressions: [
              {
                and: {
                  operators,
                },
              
              },
            ],
          };
        }


  
  return qc_header.qc;
}
export function getQcOrderDeliverySchedule(filter: any,offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: "order-search-filter-deliveryschedule",
      offset: offset || 0,
      limit: limit || 100,
    },
  };
  
  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];
  const operatorsName = [];

  if (filter.assignedUser) {
    operators.push(
      getOperator(
        "order_assigned_user",
        "user_resourceuid",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.assignedUser),
        "assignedUser"
      )
    );
  }

  if (filter && filter.planningType) {
    operators.push(getOperator("order_order_planning_type","internal_code","EQUAL",globalConfig.formatters.getEqualValue(filter.planningType),"planningType"));
    if (filter && filter.category) {
      operators.push(getOperator("order_order_planning_type","id","EQUAL",globalConfig.formatters.getEqualValue(filter.category),"category"));
    }
  }

  if(filter.planingEvent){
    operators.push(getInOperator("order_order", "status_code", "IN", null, null, globalConfig.formatters.getInValue(['DELIVERED','PLANNED','TO_PLAN','TO_BE_RESCHEDULED'])))
  }
    if (filter.orderPlanningId) {
    operators.push(
      getOperator(
        "order_order_planning",
        "id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.orderPlanningId),
        "id"
      )
    );
  }

  if (filter.filterReference) {
    operators.push(
      getOperator(
        "lower(order_order",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.filterReference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.externalReference) {
    operators.push(
      getOperator(
        "lower(order_order_item",
        "external_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.externalReference + "%")
          .toLowerCase(),
        "external_reference"
      )
    );
  }
  if (filter.filterStatus) {
    operators.push(
      getOperator(
        "order_order",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.filterStatus),
        "status_code"
      )
    );
  }
  if (filter.manufacturingNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "num_fabrication)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.manufacturingNumber + "%")
          .toLowerCase(),
        "num_fabrication"
      )
    );
  }
  if (filter.registrationNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "matriculation)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.registrationNumber + "%")
          .toLowerCase(),
        "matriculation"
      )
    );
  }
  if (filter.activity !== undefined) {
    operators.push(
      getOperator(
        "order_order_asset",
        "flag_new",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.activity),
        "flag_new"
      )
    );
  }
  if (filter.delivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNOTNULL")
    );
  }
  if (filter.notDelivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNULL")
    );
  }
  if (filter.brand) {
    operators.push(
      getOperator(
        "order_order_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand),
        "brand"
      )
    );
  }
  if (filter.model) {
    operators.push(
      getOperator(
        "order_order_asset",
        "range",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.model),
        "range"
      )
    );
  }
  if (filter.chassisReference) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "chassis_reference)",
        "LIKE",
        globalConfig.formatters
        .getEqualValue("%" + filter.chassisReference + "%")
        .toLowerCase(),
        "chassis_reference"
      )
    );
  }
  if (filter.contractStatus) {
    operators.push(
      getOperator(
        "order_order_item",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.contractStatus),
        "status_code"
      )
    );
  }

  if (filter.deliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order_item",
        "initial_delivery_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.deliveryDateFrom, "DD/MM/YYYY", "fr").toString,
        "deliveryDateFrom"
      )
    );
  }
  if (filter.deliveryDateTo) {
    operators.push(
      getOperator(
        "order_order_item",
        "initial_delivery_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.deliveryDateTo, "DD/MM/YYYY", "fr").toString,
        "deliveryDateTo"
      )
    );
  }
  if (filter.previsonalDeliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order_item",
        "prevision_delivery_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.previsonalDeliveryDateFrom, "DD/MM/YYYY", "fr")
          .toString,
        "previsonalDeliveryDateFrom"
      )
    );
  }
  if (filter.previsonalDeliveryDateTo) {
    operators.push(
      getOperator(
        "order_order_item",
        "prevision_delivery_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.previsonalDeliveryDateTo, "DD/MM/YYYY", "fr")
          .toString,
        "previsonalDeliveryDateTo"
      )
    );
  }
  if (filter.supplierName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.supplierName + "%")
          .toLowerCase(),
        "name"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.clientReference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.family_name) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "family_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%"+ filter.family_name + "%")
          .toLowerCase(),
        "family_name"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "first_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%"+ filter.firstName + "%")
          .toLowerCase(),
        "first_name"
      )
    );
  }
  if (filter.commercial_name) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%"+ filter.commercial_name + "%")
          .toLowerCase(),
        "commercial_name"
      )
    );
  }
    if (filter.name) {
      const filterValue = globalConfig.formatters.getEqualValue("%" + filter.name + "%").toLowerCase();
      operatorsName.push(
          getOperator(
          "lower(party_party_1",
          "family_name)",
          "LIKE",
          filterValue,
          "family_name"
        )
      );
      operatorsName.push(
        getOperator(
          "lower(party_party_1",
          "first_name)",
          "LIKE",
          filterValue,
          "first_name"
        )
      );
      operatorsName.push(
        getOperator(
          "lower(party_party_1",
          "commercial_name)",
          "LIKE",
          filterValue,
          "commercial_name"
        )
      );
    }
  /*if (filter.role_code && filter.role_code.length > 0) {
    operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.role_code),
            "role_code"
        )
    );
  }*/ 
  if (filter.partyRole && filter.partyRole.length > 0) {
    operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.partyRole),
            "role_code"
        )
    );
  } 
  if (filter.preconditionsStatus) {
    operators.push(
      getOperator(
        "supportingdocument_check_points",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.preconditionsStatus),
        "status_code"
    )
    );
  }
  if (filter.financingType) {
    operators.push(
      getOperator(
        "order_order_financing",
        "label",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.financingType),
        "label"
      )
    );
  }
  if (filter.preconditionsList) {
    operators.push(
      getOperator(
        "supportingdocument_check_points",
        "check_points_config_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.preconditionsList),
        "check_points_config_code"
    )
    );
  }
  if (filter.preconditionDate) {
    operators.push(
      getOperator(
        "cast(supportingdocument_received_file",
        "reception_date  AS date)",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.preconditionDate),
        "reception_date"
    )
    );
  }
  /*if (filter.immatriculationDate) {
    operators.push(
      getOperator(
        "cast(order_order_asset",
        "matriculation_date  AS date)",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.immatriculationDate),
        "matriculation_date"
    )
    );
  }*/
  if (filter.immatriculationDateFrom) {
    operators.push(
      getOperator(
        "order_order_asset",
        "matriculation_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.immatriculationDateFrom, "DD/MM/YYYY", "fr").toString,
        "immatriculationDateFrom"
      )
    );
  }
  if (filter.immatriculationDateTo) {
    operators.push(
      getOperator(
        "order_order_asset",
        "matriculation_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.immatriculationDateTo, "DD/MM/YYYY", "fr").toString,
        "immatriculationDateTo"
      )
    );
  }
  if (filter.orderCreatedDate) {
    operators.push(
      getOperator(
        "cast(order_order",
        "_when_created  AS date)",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.orderCreatedDate),
        "_when_created"
    )
    );
  }
  if (!filter.tradeInImmatriculation) {
    operators.push(
      getOperator("order_order_asset", "asset_trade_in_id", "ISNULL")
    );
  }
  if (filter.tradeInImmatriculation) {
    operators.push(
      getOperator("order_order_asset", "asset_trade_in_id", "ISNOTNULL")
    );
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "matriculation)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue("%" + filter.tradeInImmatriculation + "%")
          .toLowerCase(),
        "matriculation"
      )
    );
  }
  else {
    operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            "'CUSTOMER'",
            "role_code"
        )
    );
  }

      if ( operatorsName.length> 0 ){
            operators.push( { or: {
              operators :[... operatorsName]
            } 
          })
      }
      
    if (operators.length > 0 ) {
          qc_header.qc.otherFilters = {
            expressions: [
              {
                and: {
                  operators,
                },
              
              },
            ],
          };
        }


  
  return qc_header.qc;
}
export function searchCount(filter: any): Promise<any[]> {
  return new Promise<any[]>((resolve, reject) => {
    const url = settings.api_query_count_url;
    const _headers = headers({
      qc: JSON.stringify(filter.businessData),
    });
    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export function getQcOffer(filter: any,offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: "offer-filter-search",
      offset: offset || 0,
      limit: limit || 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];

  if (filter.demandStatus) {
    operators.push(
      getOperator(
        "offer_offer_status",
        "id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.demandStatus),
        "id"
      )
    );
  }
  if (filter.reference) {
    operators.push(
      getOperator(
        "lower(offer_offer",
        "_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.reference + "%")
          .toLowerCase(),
        "_reference"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "first_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.firstName + "%")
          .toLowerCase(),
        "firstName"
      )
    );
  }
  if (filter.familyName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "family_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.familyName + "%")
          .toLowerCase(),
        "familyName"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.clientReference + "%")
          .toLowerCase(),
        "clientReference"
      )
    );
  }
  if (filter.from) {
    operators.push(
      getOperator(
        "offer_offer",
        "_when_created",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.from, "DD/MM/YYYY", "fr").toString,
        "from"
      )
    );
  }
  if (filter.until) {
    operators.push(
      getOperator(
        "offer_offer",
        "_when_created",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.until, "DD/MM/YYYY", "fr").toString,
        "until"
      )
    );
  }
  if (filter.channel) {
    operators.push(
      getOperator(
        "offer_offer",
        "sales_channel_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.channel.id),
        "channel"
      )
    );
  }
  if (filter.typeClient) {
    operators.push(
      getOperator(
        "party_party",
        "_discriminator",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.typeClient.id),
        "typeClient"
      )
    );
  }
  if (filter.network) {
    operators.push(
      getOperator(
        "offer_offer",
        "associated_networks_id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.network.id),
        "network"
      )
    );
  }
  if (filter.networkNode) {
    operators.push(
      getOperator(
        "offer_offer",
        "associated_network_nodes_id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.networkNode.id),
        "networkNode"
      )
    );
  }
  if (filter._decisionAnalysisProcessId) {
    operators.push(
      getOperator(
        "offer_offer",
        "_decisionAnalysisProcessId",
        "EQUAL",
        globalConfig.formatters.getEqualValue(
          filter._decisionAnalysisProcessId.id
        ),
        "_decisionAnalysisProcessId"
      )
    );
  }
  if (filter.brand) {
    operators.push(
      getOperator(
        "offer_proposal_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand.id),
        "brand"
      )
    );
  }
  if (filter.range) {
    operators.push(
      getOperator(
        "offer_proposal_asset",
        "range",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.range.id),
        "range"
      )
    );
  }
  if (filter.immatriculation) {
    operators.push(
      getOperator(
        "lower(offer_proposal_asset",
        "matriculation)",
        "EQUAL",
        globalConfig.formatters
          .getEqualValue(filter.immatriculation)
          .toLowerCase(),
        "immatriculation"
      )
    );
  }
  if (filter.commercialName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.commercialName + "%")
          .toLowerCase(),
        "commercialName"
      )
    );
  }
  if (filter.siren) {
    operators.push(
      getOperator(
        "lower(party_party",
        "siren)",
        "LIKE",
        globalConfig.formatters.getEqualValue(filter.siren + "%").toLowerCase(),
        "siren"
      )
    );
  }
  if (filter.role && filter.role.length > 0) {
    operators.push(
      getInOperator(
        "party_party",
        "id",
        "IN",
        null,
        null,
        globalConfig.formatters.getInValue(filter.role)
      )
    );
  } else {
    operators.push(
      getOperator(
        "offer_associated_party",
        "role_code",
        "EQUAL",
        "'CUSTOMER'",
        "role_code"
      )
    );
  }

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export function getQcParty(filter: any, offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: "party-filter-search",
      offset: offset || 0,
      limit: limit || 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];
  if (filter._reference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "_reference)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter._reference).toLowerCase(),
        "_reference"
      )
    );
  }
  if (filter.reference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "_reference)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.reference).toLowerCase(),
        "_reference"
      )
    );
  }
  if (filter.siren) {
    operators.push(
      getOperator(
        "lower(party_party",
        "siren)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.siren).toLowerCase(),
        "siren"
      )
    );
  }
  if (filter.family_name) {
    operators.push(
      getOperator(
        "lower(party_party",
        "family_name)",
        "LIKE",
          `'${filter.family_name.toLowerCase()}'`,
        //CF-379
        //globalConfig.formatters.getLikeValue(filter.family_name).toLowerCase(),
        "family_name"
      )
    );
  }

  if (filter.birthday) {
    operators.push(
      getOperator(
        "party_party",
        "birth_date",
        "EQUAL",
        //formatDateLocale(filter.birthday, "DD/MM/YYYY", "fr").toString,
        globalConfig.formatters.getEqualValue(moment(filter.birthday,  'DD/MM/YYYY').format('YYYY-MM-DD')),
        "birthday"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "first_name)",
        "LIKE",
        //CF-379
        `'${filter.firstName.toLowerCase()}'`,
        //globalConfig.formatters.getLikeValue(filter.firstName.toLowerCase()),
        "firstName"
      )
    );
  }
  if (filter.discriminator) {
    operators.push(
      getOperator(
        "party_party",
        "_discriminator",
        "LIKE",
        globalConfig.formatters.getLikeValue(
          filter.discriminator === "party-FrenchPerson"
            ? "Person"
            : "Organization"
        ),
        "discriminator"
      )
    );
  }
  if (filter.city) {
    operators.push(
      getOperator(
        "party_postal_code",
        "city",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.city),
        "city"
      )
    );
  }
  if (filter.postalCode) {
    operators.push(
      getOperator(
        "party_postal_code",
        "postal_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.postalCode),
        "postalCode"
      )
    );
  }
  if (filter.siret) {
    operators.push(
      getOperator(
        "lower(party_facility",
        "siret)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.siret).toLowerCase(),
        "siret"
      )
    );
  }
  if (filter.phoneNumber) {
    operators.push(
      getOperator(
        "party_contact_mechanism",
        "phone_number",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.phoneNumber),
        "phoneNumber"
      )
    );
  }
  if (filter.relationTypeCode) {
    operators.push(
      getOperator(
        "party_party_relation",
        "relation_type_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.relationTypeCode),
        "relationTypeCode"
      )
    );
  }
  if (filter.role) {
    operators.push(
      getOperator(
        "party_party_role",
        "role_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.role.value),
        "role_code"
      )
    );
  }
  if (filter.commercial_name) {
    operators.push(
      getOperator(
        "lower(party_party",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters.getLikeValue(
          filter.commercial_name.toLowerCase()
        ),
        "commercial_name"
      )
    );
  }
  if (filter.current_status_code) {
    operators.push(
      getOperator(
        "party_party",
        "current_status_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.current_status_code.id),
        "current_status_code"
      )
    );
  }
  if (filter.externalReference) {
    operators.push(
      getOperator(
        "party_party",
        "external_reference",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.externalReference),
        "external_reference"
      )
    );
  }

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export async function deleteFilter(filter: any) {
  const { resourceUid, systemUid } = filter;
  const url = `/${systemUid}/api/1/${systemUid}/filterstore/${resourceUid}/`;
  return (await api().delete(url));
}

export function isFilterDAAQMatching(filterDaaq: string, connectedUserDaaq: { readDAAQ: string, defaultCreateDAAQ: string }){
  const { readDAAQ, defaultCreateDAAQ } = connectedUserDaaq;
  if (readDAAQ === "/" || defaultCreateDAAQ === "/") return true; // In case user super admin or filter public.
  const segments = filterDaaq.split("/").filter((segment: string) => segment);
  const patterns = segments.map((_: any, index: number) => {
    const subPath = segments.slice(0, index + 1).join("/");
    return new RegExp(`^/${subPath}/$`);
  });
  return patterns.some((pattern: RegExp) => pattern.test(readDAAQ) || pattern.test(defaultCreateDAAQ));
}