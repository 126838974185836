import {
    BankAgency,
    ContactType,
    FilterParty,
    Organization,
    PartyContactMechanism,
    Person,
    Relation,
    SearchBankAgency,
    SearchOrganization,
    SearchPerson,
    SearchSupplier,
    Third,
    ThirdType
} from "@/types";
import {settings} from "@/settings";
import {deepClone, getEntity, globalConfig, headers, mountSelectFields, Paths, UON} from "@/utils";
import axios from "axios";
import {formatDateLocale, getYearsOfDate, isValidDate} from "@/utils/configuration/formatters-config";
import store from "@/store";
import {useApi} from "@/requests/useApi";
import {buildManagerRelation, buildPartyTypePreference} from "@/commons/party-middleware";
import i18n from "@/i18n";
import {buildJobs} from "./thirdService";
import { getQcParty } from "./filter/filterService";
import {getNature} from "@/store/services/picklist";
import { api } from "@/auth/api";
import { getPartyDocument } from "./order/orderService";
import moment from "moment";

const odm_party = 'odm_party'

function getOperator(table: string, field: string, operator: string, value: any, parameter: string) {
    return {
        "expression": {
            "table": table,
            "field": field,
            "operator": operator,
            "value": value,
            "parameter": parameter
        }
    }
}

function getValidUniformOdmName(objectType: string, resourceUid?: string | '', systemUid?: string | '') {
    if (resourceUid && resourceUid !== '') {
        return {
            objectType: objectType,
            systemUid: systemUid,
            resourceUid: resourceUid,
        }
    } else {
        return undefined
    }
}

export function getContactType(id: string, label: string, objectType: string, fields: string[]) {
    return {
        id: id,
        label: i18n.global.t(`party.typeComunication.${label}`),
        config: {
            objectType: objectType,
            fields: fields
        }
    }
}

export async function getNatureIncome(code: string) {
    const natures : any = await getNature();
    return natures.items.filter((item: any) => item.config.internalCode === code)
}

function blockContactMechanismType(party: Organization | Person) {
    party.contacts?.forEach((contact: PartyContactMechanism) => {
        if (contact.contactMechanism.objectType !== '') {
            contact.contactMechanism.isDisabled = true
            contact.contactMechanism.blocked = true
        }
    })
}

export function buildExistingRevenues(person: Person, natureIncome: any) {
    if (person?.revenues && person.revenues?.length > 0) {
        person.revenues.forEach((item: any) => {
            item.detail.forEach((el: any) => {
                const isNatureIncome = natureIncome.some((na: any) => el.nature && na.value === el.nature?.resourceUid)
                const type = isNatureIncome ? 'INCOME' : 'EXPENSE'
                el.type = type
                el.value = el.value.amount
                if(el.periodicity&&el.periodicity.resourceUid){
                    el.periodicity = {
                        type: {
                            label: i18n.global.t(`party.periodicity.${el.periodicity.resourceUid}`),
                            value: el.periodicity.resourceUid
                        }
                    }
                }else{
                    el.periodicity = {
                        type: {
                            label: '',
                            value: ''
                        }
                    }
                }
                if(el.nature&&el.nature.resourceUid){
                    el.nature = {
                        type: {
                            label: el.nature.resourceUid,
                            value: el.nature.resourceUid
                        }
                    }
                }else{
                    el.nature = {
                        type: {
                            label: '',
                            value: ''
                        }
                    }
                }
                el.nature = {
                    type: {
                        label: el.nature.resourceUid,
                        value: el.nature.resourceUid
                    }
                }
                el.validity = {
                    from: el.validity?.from,
                    until: el.validity?.until,
                }
            })
        })
    }
    else {
        person.revenues = [{ ...store.getters["demandModule/getThirdAnalysisInit"] }]
    }
}

export const getParty = async (type: string, resourceUid: string) => {

        const path = type.toUpperCase().includes('PERSON') ? Paths.frenchPerson : Paths.frenchOrganization
        return (await useApi(UON.SystemUid.odmOrchestration, path).get(resourceUid)).response
    
}

export async function getThird(resourceUid: string, type: string, whenCreated: string) {
    
    const response = await getParty(type, resourceUid);
    const cloneResponse =deepClone(response)
    store.dispatch("orderModule/setNewParty",cloneResponse)
    const party = getBuildThird(response,type , whenCreated)
    return party
}


export async function getBuildThird(response :any , type: string, whenCreated: string ){

    const isPerson = type.toUpperCase().includes('PERSON')    
    response?.contacts?.map((item: any) => buildContacts(item))


    const natureIncome = await getNatureIncome('INCOME')
    if(response?.roles){
        for (const item of response.roles) {
            if (item.role && item.role.resourceUid) {
                item.role = {
                    type: {
                        value: item.role.resourceUid,
                        label: item.role.resourceUid[0] + item.role.resourceUid.substring(1).toLowerCase()
                    }
                }
            } else {
                item.role = {
                    type: {
                        value: "",
                    },
                };
            }
            if (item.status) {
                item.status = {
                    type: {
                        value: item.status.resourceUid,
                        label: item.status.resourceUid[0] + item.status.resourceUid.substring(1).toLowerCase()
                    }
                }
            } else {
                item.status = {
                    type: {
                        value: "",
                    },
                };
            }
            item.isDisabled = true
            if (item.validity) {
                item.validity.from = item.validity?.from ? item.validity.from : ''
                item.validity.until = item.validity?.until ? item.validity.until : ''
            } else {
                item.validity = {
                    from: '',
                    until: ''
                }
            }
        
            await buildPartyTypePreference(item.preferences, response.addresses);
        
        
        }
    }
    if (response.addresses){
        response.addresses?.forEach((adresse: any) => {
            if( adresse.address &&  !adresse.address.type){
                adresse.address.type = {
                    type: {
                        value: "",
                        label: ""
                    }
                }
            }
            if (!adresse.livingSituation){
                adresse.livingSituation={
                    type: {
                        value: "",
                        label: ""
                    }
                }
            }
            else{
                adresse.livingSituation={
                    type: {
                        value: adresse.livingSituation?.resourceUid,
                        label: i18n.global.t(
                            "middleOffice.party.person.address.livingstatus." +
                            adresse.livingSituation?.resourceUid
                        )
                    }
                }
            }

            if (adresse.address.postalCode) {
                if (adresse.address.postalCode.country) {
                    adresse.address.postalCode.country.name = 'France'
                }
                else {
                    adresse.address.postalCode.country = {
                        name: 'France'
                    }
                }
                adresse.address.postalCode.type = {
                    id: adresse.address.postalCode.resourceUid,
                    label: adresse.address.postalCode.postalCode+' - '+adresse.address.postalCode.city
                    
                }
                adresse.address.postalCode.postalCode = adresse.address.postalCode.resourceUid
            }
            else{
                adresse.address.postalCode={
                    country:{
                        name:""
                    },
                    type:{
                        id: '',
                        label: ''
                    }
                }
                }
            
        })
    }
       

    response.classifications?.forEach(async (node: any) => {
        if (node.partyClass.resourceUid || node.partyClass.type.value) {
              if (node.partyClass.classType && node.partyClass.classType.resourceUid) {
                  node.classType = {value:node.partyClass.classType.resourceUid,
                    label:i18n.global.t("picklists.partyClassificationType."+node.partyClass.classType.resourceUid)
                  }
              }
          }
        node.partyClass = {
            type: {
                value: node.partyClass.resourceUid || node.partyClass.type.value,
                label:i18n.global.t("demand.party.partyClass."+node.partyClass.resourceUid || node.partyClass.type.value)
            }
        }
        if (node.partyClass.resourceUid || node.partyClass.type.value) {
          /*  const data = await getEntity('odm-party', 'partyclass', node.partyClass.resourceUid || node.partyClass.type.value)*/
            if (node.classType && node.classType.resourceUid) {
                node.classType = node.classType.resourceUid
            }
        }

        node.isDisabled = true
        if (node.validity) {
            node.validity.from = node.validity?.from ? node.validity.from : ''
            node.validity.until = node.validity?.until ? node.validity.until : ''
        } else {
            node.validity = {
                from: '',
                until: ''
            }
        }
    })
    if (response.taxGeographicZone && response.taxGeographicZone.resourceUid) {
        response.taxGeographicZone = response.taxGeographicZone.resourceUid
    }

    response.financialExposures = response.financialExposures.map((item: any) => buildFinancialExposure(item))

    response.accounts.map(async (item: any) => await buildBankAccount(item))
   
    
    for (const correspondents of response.correspondents) {
        for (const contactCorrespondent of correspondents.contactCorrespondent) {
            //contactCorrespondent.contactMechanism = await getEntity('odm-party', 'contactmechanism', contactCorrespondent.contactMechanism.resourceUid)
            await buildContacts(contactCorrespondent)
        }
        if(correspondents && correspondents.qualificationType) {
            correspondents.qualificationType.type = {
                value: correspondents.qualificationType?.resourceUid
            }
        }
        if(correspondents && !correspondents.qualificationType) {
            correspondents.qualificationType={
                type : {
                        value: correspondents.qualificationType?.resourceUid
                        }
                }
        }
    }

    let person = isPerson ? response : undefined
    const organization = !isPerson ? response : undefined

    if (response.objectType.includes('person')) {
        if (person && person.revenues && person.revenues.length == 0) {
             person.revenues = []
        } else {
            buildExistingRevenues(person, natureIncome);
        }
        if (person.businessData) {
            person.homemakers = person.businessData.homemakers
            person.retirementPassage = person.businessData.retirementPassage
            person.birthChildren = person.businessData.birthChildren
        }
        person.nationality = {
            type: {
                label: (person.citizenships && person.citizenships.length > 0) ? person.citizenships[0].country.resourceUid : '',
                value: (person.citizenships && person.citizenships.length > 0) ? person.citizenships[0].country.resourceUid : '',
            }
        }
        if (person.birthDate && isValidDate(person.birthDate, 'DD/MM/YYYY', 'fr')) {
            person.age = getYearsOfDate(formatDateLocale(person.birthDate, 'DD/MM/YYYY', 'us', 'YYYY-MM-DD'))
        }
        if (person.birthplace) {
          //  const data = await getEntity('odm-party', 'frenchpostalcode', person.birthplace.resourceUid)
            person.birthplace = {
                type: {
                    id: person.birthplace.resourceUid,
                    label: `${person.birthplace.postalCode} - ${person.birthplace.city}`,
                    config: {
                        city: person.birthplace.city,
                        department :person.birthplace.department,
                        country: {
                            id:person.birthplace.country ? person.birthplace.country.resourceUid : '',
                            label: person.birthplace.country ? person.birthplace.country.resourceUid : ''
                        },
                    }
                },
                postalCode: person.birthplace ? person.birthplace.resourceUid : ''
            }

        } else {
            person.birthplace = {
                type: {
                    id: person.birthplace ? person.birthplace.resourceUid : '',
                    label: person.birthplace ? person.birthplace.resourceUid : '',
                    config: {
                        city: '',
                        department : '',
                        country: {
                            id: '',
                            label: ''
                        },
                    }
                },
                postalCode: person.birthplace ? person.birthplace.resourceUid : ''
            }

        }

        person.title = {
            type: {
                label: person.title ? person.title.resourceUid : '',
                value: person.title ? person.title.resourceUid : ''
            }
        }
        let maritalStatus = ''
        let matrimonialRegime = ''
        if (person.maritalStatuses && person.maritalStatuses.length > 0) {
            maritalStatus = person.maritalStatuses[0].maritalStatus.resourceUid
            matrimonialRegime = person.maritalStatuses[0].matrimonialRegime.resourceUid
        }
        person.currentMatrimonialRegime = {
            type: {
                label: matrimonialRegime,
                value: matrimonialRegime
            }
        }
        person.currentMaritalStatus = {
            type: {
                label: maritalStatus,
                value: maritalStatus
            }
        }
        person.gender = {
            type: {
                label: person.gender ? person.gender.resourceUid : '',
                value: person.gender ? person.gender.resourceUid : ''
            }
        }
        person.ids.forEach((node: any) => {

            if (node.documentType) {
                node.documentType.type = {
                    value: node.documentType.resourceUid,
                    label: node.documentType.resourceUid?.toString() !== "" ? i18n.global.t("middleOffice.party.person.identityDoc.docTypes." + node.documentType.resourceUid) : ''
                }
            }
            else {
                node.documentType = {
                    type: {
                        value: '',
                        label: ''
                    }
                }
            }
            node.validity = node.validity || { from: '', until: '' }
        })

        if (person.jobs.length) {
            person =  await buildJobs(person, false)
            person.jobs?.forEach((element: any) => {
                if (!element.occupationType) {
                    element.occupationType = {
                        type: {
                            id: undefined,
                            label: undefined
                        },
                    }
                }
                if (!element.contractType) {
                    element.contractType = {
                        type: {
                            id: undefined,
                            label: undefined
                        },
                    }
                }

            });
        }
        ;

       // const partyRelations = (await buildManagerRelation(person))?.load()
       // if (partyRelations) {
            person.relations = []
       // }
        if(person.legalEntity){
            if ( person.legalEntity.legalCategory) {
                person.legalEntity.legalCategory.type = {
                    value: person.legalEntity.legalCategory.resourceUid,
                }
            } else {
                person.legalEntity.activitySector = {
                    type: {
                        value: ''
                    }
                }
            }
        }
        else if(person.businessData && person.businessData.szTypeClient && person.businessData.szTypeClient ==='PP'){
            person.legalEntity={
                objectType: 'odm.party.party.organization.frenchorganization',
                siren: '',//
                name: '',
                creationDate: '',//
                activitySector: {//
                  objectType: 'odm.party.activitysector',
                  resourceUid: '',
                  type: {
                    value: undefined,
                    label: ''
                  }
                },
                legalCategory: {
                  objectType: 'odm.party.organizationlegalcategory',
                  resourceUid: '',
                  type: {
                    value: '',
                  }
                }
              }
        }
        
    }
     else {        
        organization.facilities.map(async (item: any) => await buildFacility(item))
        for (const item of response.roles) {
            await buildPartyTypePreference(item.preferences, response.facilities);
        }
        if (organization.legalCategory) {
            organization.legalCategory.type = {
                value: organization.legalCategory.resourceUid,
            }
        } else {
            organization.legalCategory = {
                type: {
                    value: ''
                }
            }
        }
        if (organization.codeNACE && organization.codeNACE.resourceUid) {
            organization.codeNACE = {
                type: {
                    label: organization.codeNACE.resourceUid?.toString() !== "" ? `${organization.codeNACE.resourceUid} - ${i18n.global.t('picklists.codeNace.'.concat(organization.codeNACE.resourceUid))}` : '',
                    value: organization.codeNACE.resourceUid,
                }
            }
        } else {
            organization.codeNACE = {
                type: {
                    value: '',
                    label: ''
                }
            }
        }

        if (organization.activitySector) {
            organization.activitySector.type = {
                value: organization.activitySector.resourceUid,
            }
        } else {
            organization.activitySector = {
                type: {
                    value: ''
                }
            }
        }
        if (organization.balanceSheets && organization.balanceSheets.length !== 0) {
            const ListBalanceSheets: any[] = []
            organization.balanceSheets.forEach(async (item: any) => {
                const balanceSheet = await buildBalanceSheets(item)
                ListBalanceSheets.push(balanceSheet)
            });
            organization.balanceSheets = ListBalanceSheets
        }

        ///organization.balanceSheets = organization.balanceSheets.map(async (item: any) => await buildBalanceSheets(item))
       
        organization.relations = [];
        // we dont have relation screen that's why we put [] if we have juste decomment this

       /* let partyRelations = (await buildManagerRelation(organization))?.loadAscRelations();
        if (partyRelations) {
            const relations = [];
            for (const rel of partyRelations) {
                const { response } = await useApi(UON.SystemUid.odmParty, Paths.frenchPerson).get(rel.partyAsc.resourceUid);
                relations.push({
                    ...rel,
                    relation: {
                        type: {
                            value: 'ascending',
                        }
                    },
                    name: (response && response.firstName) || '',
                });
            }
            organization.relations = relations;
            mountSelectFields(organization.relations, ['relationType'])
        }
        else {
            organization.manager = store.getters['demandModule/getManagerInit']
        }*/

        response.addresses.forEach((adresse: any) => {
            delete adresse.livingSituation
        });

        organization.facilities.forEach((adresse: any) => {
            delete adresse.livingSituation
        });
        
    }
    if (response.documents && response.documents.length){
        const partyReference = response.resourceUid
        await handelDocumentsParty(response.documents,partyReference)
    }

    return {
        id: getValidUniformOdmName(response.objectType, response.resourceUid, response.systemUid),
        type: { id: response?.businessData?.szTypeClient == 'PP' ?'party-FrenchProfessional':type, label: isPerson ? response?.businessData?.szTypeClient == 'PP' ? i18n.global.t("enum.party.type.party-FrenchProfessional") : i18n.global.t("enum.party.type.party-FrenchPerson") : i18n.global.t("enum.party.type.party-FrenchOrganization") },
        isDisabled: true,
        whenCreated: whenCreated,
        organization: organization,
        person: person,
        addresses: response.addresses,
        accounts: response.accounts,
        financialExposures: response.financialExposures,
        relations: response.relations,
        roles: response.roles,
        correspondents: response.correspondents,
        classifications: response.classifications,
        documents:response.documents
    }
}



export function buildFinancialExposure(node: any) {

    return {
        resourceUid: node.resourceUid,
        objectType: node.objectType,
        systemUid: node.systemUid,
        outstandingType: {
            value: node.outstandingType?.resourceUid ? node.outstandingType?.resourceUid : "",
            label: node.outstandingType?.resourceUid ? node.outstandingType?.resourceUid : ""
        },
        financialInstitution: {
            value: node.financialInstitution?.resourceUid ? node.financialInstitution?.resourceUid : "",
            label: node.financialInstitution?.resourceUid ? node.financialInstitution?.resourceUid : ""
        },
        grossOutstanding: node.grossOutstanding?.amount,
        netOutstanding: node.netOutstanding?.amount,
        residualValue: node.residualValue?.amount,
        flagFinancialPool: node.flagFinancialPool,
        flagRiskPool: node.flagRiskPool,
        shareFinancialPool: node.shareFinancialPool,
        shareRiskPool: node.shareRiskPool,
        effectiveDate: node.effectiveDate,
        contractReference: node.contractReference,
        contractValidity: {
            from: node.contractValidity?.from,
            until: node.contractValidity?.until
        }

    }
}


export async function buildBankAccount(node: any) {
    //node.bankAccount = await getEntity('odm-party', 'frenchbankaccount', node.bankAccount.resourceUid)
    if (node.bankAccount.validity) {
        node.bankAccount.validity.from = node.bankAccount.validity?.from ? node.bankAccount.validity.from : ''
        node.bankAccount.validity.until = node.bankAccount.validity?.until ? node.bankAccount.validity.until : ''
    }
    else {
        node.bankAccount.validity = {
            from: '',
            until: ''
        }
    }
    if(node.bankAccount.type&&node.bankAccount.type.resourceUid){
        node.bankAccount.type = {
            id: node.bankAccount.type.resourceUid,
            label: node.bankAccount.type.resourceUid[0] + node.bankAccount.type.resourceUid.substring(1).toLowerCase()
        }
    }else{
        node.bankAccount.type = {
            id: '',
            label: ''
        }
    }
    if(node.bankAccount.status&&node.bankAccount.status.resourceUid){
        node.bankAccount.status = {
            id: node.bankAccount.status.resourceUid,
            label: node.bankAccount.status.resourceUid[0] + node.bankAccount.status.resourceUid.substring(1).toLowerCase()
        }
    }else{
        node.bankAccount.status = {
            id: '',
            label: ''
        } 
    }
}

export function buildRelations(partyRelations: any, organization: any) {

    const listRelation = []

    for (const item of partyRelations) {
        const getOrganizationName = !item.name ? item.commercial_name : item.name;
        const name: string = item._discriminator === ThirdType.ORGANIZATION ? getOrganizationName : item.family_name
        if (organization.resourceUid === item.party_desc_id) {
            listRelation?.push(buildRelation(item, 'ascending', name))
        }
        else {
            listRelation?.push(buildRelation(item, 'descending', name))
        }
    }

    return listRelation

}


function buildRelation(item: any, relation: string, name: string): Relation {
    return {
        objectType: 'odm.party.partyrelation',
        systemUid: 'odm-party',
        resourceUid: item.id,
        name: name,
        partyAsc: {
            resourceUid: item.party_asc_id
        },
        partyDesc: {
            resourceUid: item.party_desc_id
        },
        relation: {
            type: {
                value: relation,
            }
        },
        relationType: {
            objectType: 'odm.party.relationtype',
            resourceUid: item.relation_type_code,
            type: {
                value: item.relation_type_code
            }
        },
        validity: {
            from: item.validity_from,
            until: item.validity_until
        },
        sharingRate: item.sharing_rate

    };
}

async function buildContacts(node: any) {

    let type
    if(node.contactMechanism ){
       if (node.contactMechanism.objectType.includes('emailcontact')) {
        //    node.contactMechanism = await getEntity('odm-party', 'emailcontact', node.contactMechanism.resourceUid)
            type = getContactType(ContactType.EMAIL_CONTACT, "E-mail", "odm.party.contactmechanism.emailcontact", ["emailAddress", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq", "consent", "facility"])
        } else if (node.contactMechanism.objectType.includes('phonecontact')) {
       //     node.contactMechanism = await getEntity('odm-party', 'phonecontact', node.contactMechanism.resourceUid)
            type = getContactType(ContactType.PHONE_CONTACT, "Phone", "odm.party.contactmechanism.phonecontact", ["phoneNumber", /*"country",*/ "acceptSMS", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq"])
        } else if (node.contactMechanism.objectType.indexOf('messengercontact')) {
      //      node.contactMechanism = await getEntity('odm-party', 'messengercontact', node.contactMechanism.resourceUid)
            type = getContactType(ContactType.MESSENGER_CONTACT, "Messenger", "odm.party.contactmechanism.messengercontact", ["identifier", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq"])
        } else if (node.contactMechanism.objectType.indexOf('postalmailcontact')) {
       //     node.contactMechanism = await getEntity('odm-party', 'postalmailcontact', node.contactMechanism.resourceUid)
            type = getContactType(ContactType.POSTAL_MAIL_CONTACT, "Postal Mail", "odm.party.contactmechanism.postalmailcontact", ["address", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq"])
        }
        node.contactMechanism.type = type
        node.contactMechanism.type.config.disable = true
        node.contactMechanism.isDisabled = true
        node.contactMechanism.blocked = true
    }
}

export async function buildFacility(node: any) {
    if (node.facilityType) {
        node.facilityType.type = {
            value: node.facilityType.resourceUid,
        }
    } else {
        node.facilityType = {
            type: {
                value: ''
            }
        }
    }
    const facility = await buildAddress(node)
    if (!facility.address) {
        facility.address = { ...store.getters["thirdModule/getAddressInit"] }
    }

}

export async function buildAddress(node: any) {
    if (node.address && node.address.resourceUid) {
       // node.address = await getEntity('odm-party', 'address', node.address.resourceUid)
        //const postalCodeData = await getEntity('odm-party', 'frenchpostalcode', node.address.postalCode.resourceUid)
      //  node.address.postalCode = postalCodeData
        if (node.address.postalCode) {
            if (node.address.postalCode.country) {
                node.address.postalCode.country.name = 'France'
            }
            else {
                node.address.postalCode.country = {
                    name: 'France'
                }
            }
            node.address.postalCode.type = {
                id: node.address.postalCode.resourceUid,
                label: node.address.postalCode.postalCode
            }
        }
    }
    return node;
}

async function buildBalanceSheets(node: any) {
    if (node.status) {
        node.status = {
            id: node.status.resourceUid,
            label: node.status.resourceUid === 'NEW' ? 'Nouveau' : node.status.resourceUid === 'VALIDATED' ? 'Validé' : 'Refusé'
        }
    } else {
        node.status = {
            id: '',
            label: ''
        }
    }
    if (node.country) {
        node.country = {
            id: node.country.resourceUid,
            label: node.country.resourceUid === 'FRA' ? 'France' : 'Marroco'
        }
    } else {
        node.country = {
            id: '',
            label: ''
        }
    }
    node.period = {
        from: (node.period && node.period.from) ? node.period.from : '',
        until: (node.period && node.period.until) ? node.period.until : ''
    }
    const promises = node.detail.map((item: any) => buildBalanceSheetsItem(item))
    await Promise.all(promises)
    return node
}

async function buildBalanceSheetsItem(node: any) {
    node.nature = {
        id: node.nature.resourceUid,
        label: node.nature.resourceUid[0] + node.nature.resourceUid.substring(1).toLowerCase()
    }
}

export function filterParty(filter: FilterParty, offset?: any, limit?: any): Promise<FilterParty[]> {
    return new Promise<FilterParty[]>(async (resolve, reject) => {
        const url = settings.api_query_url;
        const qc = getQcParty(filter, offset, limit);
        const _headers = headers({
            qc: JSON.stringify(qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}




export function searchPerson(filter: SearchPerson): Promise<SearchPerson[]> {
    return new Promise<SearchPerson[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'simulation-party-person-search',
                "offset": 0,
                "limit": 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter.discriminator) {
            operators.push(getOperator("party_party", "_discriminator", "EQUAL", `'${filter.discriminator}'`, "discriminator"))
        }

        if (filter.reference) {
            operators.push(getOperator("party_party", "_reference", "LIKE", globalConfig.formatters.getLikeValue(filter.reference), "_reference"))
        }

        if (filter.firstName) {
            operators.push(getOperator("party_party", "first_name", "LIKE", null, "firstName"))
        }

        if (filter.familyName) {
            operators.push(getOperator("party_party", "family_name", "LIKE", null, "familyName"))
        }

        if (filter.birthDate) {
            operators.push(getOperator("party_party", "birth_date", "EQUAL", globalConfig.formatters.getLikeValue(formatDateLocale(filter.birthDate, 'DD/MM/YYYY', 'fr')), "birthDate"))
        }

        if (filter.phoneNumber) {
            operators.push(getOperator("party_contact_mechanism", "phone_number", "LIKE", null, "phoneNumber"))
        }

        if (filter.territory) {
            operators.push(getOperator("party_address", "territory", "LIKE", globalConfig.formatters.getLikeValue(filter.territory), "city"))
        }

        if (filter.postalCode) {
            filter.postalCode = filter.postalCode.config.postal_code
            operators.push(getOperator("party_address", "postal_code_id", "EQUAL", globalConfig.formatters.getLikeValue(filter.postalCode), "postalCode"))
        }
        if (filter.role) {
            operators.push(getOperator("party_party_role", "role_code", "LIKE", globalConfig.formatters.getLikeValue(filter.role), "role_code"))
        }
        operators.push(getOperator("party_party_role", "status_code", "NOT_EQUAL", "'CLOSED'", "status_code"))
        //qc_header.qc.discriminator="party-person";
        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

export function searchBankAgency(filter: SearchBankAgency): Promise<BankAgency[]> {
    return new Promise<BankAgency[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'simulation-party-bank-agency-search',
                offset: 0,
                limit: 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []

        if (filter.name) {
            operators.push(getOperator("party_bank_agency", "_name", "LIKE", globalConfig.formatters.getLikeValue(filter.name), "name"))
        }

        if (filter.code) {
            operators.push(getOperator("party_bank_agency", "code", "LIKE", globalConfig.formatters.getLikeValue(filter.code), "code"))
        }

        if (filter.bankId) {
            operators.push(getOperator("party_bank_agency", "bank_id", "LIKE", globalConfig.formatters.getLikeValue(filter.bankId), "bank_id"))
        }

        if (filter.codeSwift) {
            operators.push(getOperator("party_bank_agency", "code_swift", "LIKE", globalConfig.formatters.getLikeValue(filter.codeSwift), "code_swift"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        or: {
                            operators
                        }
                    }
                ]
            }
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

export function searchSupplierAddress(filter: any): Promise<any> {
    return new Promise<SearchOrganization[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'asset-supplier-address-search',
                "offset": 0,
                "limit": 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }
        filter.preference_type_code = 'LIVRAISON'
        const operators = []
        operators.push(getOperator("party_party_preference", "preference_type_code", "EQUAL", `'${filter.preference_type_code}'`, "preference_type_code"))

        if (filter.id) {
            operators.push(getOperator("party_party", "id", "EQUAL", `'${filter.id}'`, "id"))
        }
        if (filter.offer_associated_party) {
            operators.push(getOperator("offer_associated_party", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.offer_associated_party), "offer_associated_party"))
        }
        if (filter.street_num) {
            operators.push(getOperator("party_address", "street_num", "LIKE", globalConfig.formatters.getLikeValue(filter.street_num), "street_num"))
        }
        if (filter.street) {
            operators.push(getOperator("party_address", "street", "LIKE", globalConfig.formatters.getLikeValue(filter.street), "street"))
        }
        if (filter.postalCode) {
            operators.push(getOperator("party_postal_code", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.postalCode), "postalCode"))
        }
        if (filter.city) {
            operators.push(getOperator("party_postal_code", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.city), "city"))
        }
        if (filter.country_code) {
            operators.push(getOperator("party_postal_code", "country_code", "LIKE", globalConfig.formatters.getLikeValue(filter.country_code), "country_code"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
        }


        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

export function searchOrganization(filter: SearchOrganization): Promise<SearchOrganization[]> {
    return new Promise<SearchOrganization[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'simulation-party-organization-search',
                "offset": 0,
                "limit": 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter.discriminator) {
            operators.push(getOperator("party_party", "_discriminator", "EQUAL", `'${filter.discriminator}'`, "discriminator"))
        }

        if (filter.reference) {
            operators.push(getOperator("party_party", "_reference", "LIKE", globalConfig.formatters.getLikeValue(filter.reference), "_reference"))
        }

        if (filter.name) {
            operators.push(getOperator("party_party", "_name", "LIKE", globalConfig.formatters.getLikeValue(filter.name), "name"))
        }

        if (filter.territory) {
            operators.push(getOperator("party_postal_code", "city", "LIKE", globalConfig.formatters.getLikeValue(filter.territory), "city"))
        }

        if (filter.postalCode) {
            filter.postalCode = filter.postalCode.config.postal_code
            operators.push(getOperator("party_postal_code", "postal_code", "LIKE", globalConfig.formatters.getLikeValue(filter.postalCode), "postalCode"))
        }

        if (filter.siren) {
            operators.push(getOperator("party_party", "siren", "LIKE", globalConfig.formatters.getLikeValue(filter.siren), "siren"))
        }
        if (filter.id) {
            operators.push(getOperator("party_party", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "id"))
        }
        if (filter.role) {
            operators.push(getOperator("party_party_role", "role_code", "LIKE", globalConfig.formatters.getLikeValue(filter.role), "role_code"))
        }
        operators.push(getOperator("party_party_role", "status_code", "NOT_EQUAL", "'CLOSED'", "status_code"))

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
        }


        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

export function searchOrganizationAsset(filter: SearchOrganization): Promise<SearchOrganization[]> {
    return new Promise<SearchOrganization[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'simulation-party-asset-organization-search',
                "offset": 0,
                "limit": 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter.discriminator) {
            operators.push(getOperator("party_party", "_discriminator", "EQUAL", `'${filter.discriminator}'`, "discriminator"))
        }

        if (filter.reference) {
            operators.push(getOperator("party_party", "_reference", "LIKE", globalConfig.formatters.getLikeValue(filter.reference), "_reference"))
        }

        if (filter.name) {
            operators.push(getOperator("party_party", "_name", "LIKE", globalConfig.formatters.getLikeValue(filter.name), "name"))
        }

        if (filter.territory) {
            operators.push(getOperator("party_postal_code", "city", "LIKE", globalConfig.formatters.getLikeValue(filter.territory), "city"))
        }

        if (filter.postalCode) {
            operators.push(getOperator("party_postal_code", "postal_code", "LIKE", globalConfig.formatters.getLikeValue(filter.postalCode), "postalCode"))
        }

        if (filter.siren) {
            operators.push(getOperator("party_party", "siren", "LIKE", globalConfig.formatters.getLikeValue(filter.siren), "siren"))
        }
        if (filter.id) {
            operators.push(getOperator("party_party", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "id"))
        }
        if (filter.asset) {
            operators.push(getOperator("product_product_feature ", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.asset), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
        }


        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

export function searchParties(filter: SearchPerson): Promise<SearchPerson[]> {
    return new Promise<SearchPerson[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'simulation-party-search',
                "offset": 0,
                "limit": 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }

        const operators = []
        if (filter.firstName) {
            operators.push(getOperator("party_party", "_name", "LIKE", null, "name"))
        }


        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
        }



        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

export function searchSupplier(filter: SearchSupplier): Promise<SearchSupplier[]> {
    return new Promise<SearchSupplier[]>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'simulation-party-supplier-search',
                "offset": 0,
                "limit": 100
            }
        }

        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter.discriminator) {
            operators.push(getOperator("party_party", "_discriminator", "EQUAL", `'${filter.discriminator}'`, "discriminator"))
        }

        if (filter.reference) {
            operators.push(getOperator("party_party", "_reference", "LIKE", globalConfig.formatters.getLikeValue(filter.reference), "_reference"))
        }
        if (filter.name) {
            operators.push(getOperator("party_party", "name", "LIKE", globalConfig.formatters.getLikeValue(filter.name), "name"))
        }

        if (filter.territory) {
            operators.push(getOperator("party_postal_code", "city", "LIKE", globalConfig.formatters.getLikeValue(filter.territory), "city"))
        }

        if (filter.postalCode) {
            operators.push(getOperator("party_postal_code", "postal_code", "LIKE", globalConfig.formatters.getLikeValue(filter.postalCode), "postalCode"))
        }

        if (filter.siren) {
            operators.push(getOperator("party_party", "siren", "LIKE", globalConfig.formatters.getLikeValue(filter.siren), "siren"))
        }

        if (filter.productCode) {
            operators.push(getOperator("product_provider_intervention_zone ", "product_code", "LIKE", globalConfig.formatters.getLikeValue(filter.productCode), "product_code"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
        }


        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

async function handelDocumentsParty(documentsParty:any,partyReference:string){
    for (const document of documentsParty) {
            let filter : any
                    if(document.fileReference&&document.fileReference.resourceUid){
                            filter = {
                                partyId: partyReference,
                                documentId:document.resourceUid
                            }
                           const documentInfo = await getPartyDocument(filter)
                           const addedBy = documentInfo.length ? documentInfo[0].first_name + ' ' + documentInfo[0].last_name : ""
                           document.addedBy = addedBy
                           const creationDate = documentInfo.length ? moment(documentInfo[0]._when_created, "YYYY-MM-DDTHH:mm:ss.SSSSSS").format('DD/MM/YYYY') : ""
                           document.creationDate = creationDate
                    }
                    if(document.documentType&&document.documentType.resourceUid){
                        document.documentType = {
                            type : {
                                value:document.documentType.resourceUid,
                                label: i18n.global.t("party.document.type." + document.documentType.resourceUid)
                            }
                        }
                    }
        
       }
  }