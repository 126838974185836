import moment from "moment";
import { Masks } from "@/utils";
import i18n from "@/i18n";

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function detectDateFormat(dateString: string) {
    if (dateString !== undefined) {
        dateString = dateString.trim();

        const regexDateTime1 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/; // YYYY-MM-DD HH:mm:ss
        const regexDateTime2 = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/; // DD/MM/YYYY HH:mm
        const regexDateTime3 = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/; // DD-MM-YYYY HH:mm

        const regexDate1 = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
        const regexDate2 = /^\d{2}\/\d{2}\/\d{4}$/; // DD/MM/YYYY
        const regexDate3 = /^\d{2}-\d{2}-\d{4}$/; // DD-MM-YYYY

        switch (true) {
            case regexDateTime1.test(dateString):
                return 'YYYY-MM-DD HH:mm:ss';
            case regexDateTime2.test(dateString):
                return 'DD/MM/YYYY HH:mm';
            case regexDateTime3.test(dateString):
                return 'DD-MM-YYYY HH:mm';
            case regexDate1.test(dateString):
                return 'YYYY-MM-DD';
            case regexDate2.test(dateString):
                return 'DD/MM/YYYY';
            case regexDate3.test(dateString):
                return 'DD-MM-YYYY';
            default:
                return 'Unknown format';
        }
    } else {
        return 'Unknown format';
    }
}

export function compareDates(date1: string, date2: string): boolean {

    let format1 = detectDateFormat(date1);
    let format2 = detectDateFormat(date2);

    function parseDate(dateString: string, format: string): Date {
        const formatParts = format.split(/[-/ :]/);
        const dateParts = dateString.split(/[-/ :]/);

        const day = parseInt(dateParts[formatParts.indexOf("DD")]);
        const month = parseInt(dateParts[formatParts.indexOf("MM")]);
        const year = parseInt(dateParts[formatParts.indexOf("YYYY")]);
        const hours = parseInt(dateParts[formatParts.indexOf("HH")] || "0");
        const minutes = parseInt(dateParts[formatParts.indexOf("mm")] || "0");
        const seconds = parseInt(dateParts[formatParts.indexOf("ss")] || "0");

        return new Date(year, month - 1, day, hours, minutes, seconds);
    }

    const parsedDate1 = parseDate(date1, format1);
    const parsedDate2 = parseDate(date2, format2);

    return parsedDate1 > parsedDate2;
}

export function formatDateLocale(date: string, inMask: string, inLocale: string, outMask = Masks.dateMask) {
    return moment(date, inMask, inLocale).format(outMask);
}

export function formatDateTimeLocale(date: string, inMask: string, inLocale: string, outMask = Masks.dateMask) {
    return moment(date, inMask, inLocale).format(outMask);
}

/**
 * Format a date value, considering internationalization and different date formats.
 *
 * This function evaluates the input date value and uses appropriate formatting based on a provided mask and locale.
 *
 * @param {any} value - The date value to format.
 * @return {string} The formatted date as a string.
 */
export function formatDateFromUntil(value: any) {
    value = value.includes("/")
        ? formatDateLocale(
            value,
            i18n.global.t("mask.format"),
            i18n.global.locale.value,
            i18n.global.t("mask.format")
        )
        : formatDateLocale(
            value,
            i18n.global.t("mask.formatWo"),
            i18n.global.locale.value,
            i18n.global.t("mask.format")
        );
}
/**
 * Format a date value for display in an international context.
 *
 * This function takes a date value and formats it for display using the configured internationalization settings.
 *
 * @param {any} date - The date value to format for display.
 * @return {string} The formatted date for display as a string.
 */
export function formatDateGlobal(date: any) {
    return formatDateLocale(
        date,
        i18n.global.t("mask.format"),
        i18n.global.locale.value,
        i18n.global.t("mask.format_en")
    );
}

export function formatDate(param: Date | string, separator: string = '/') {
    let date = typeof param === "string" ? new Date(param) : param
    return (
        [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join(separator)
    );
}

export function formatTimestamp(param: Date | string, separator: string = '/') {
    const date = typeof param === "string" ? new Date(param) : param
    return (
        [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join(separator) +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}


export function formatDateService(param: Date | string, separator: string = '/') {
    const date = typeof param === "string" ? new Date(param) : param
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join(separator)
    );
}

export function formatDateServiceAddDays(numberOfDays: number, param: Date | string, separator: string = '/') {
    const date = typeof param === "string" ? addDays(new Date(param), numberOfDays) : addDays(param, numberOfDays)
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join(separator)
    );
}

export function addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
}

export function isValidDate(dateStr: string, inMask: string, inLocale: string) {
    return dateStr.length == 10 && moment(dateStr, inMask, inLocale).isValid();
}

export function getYearsOfDate(dateStr: string) {
    return moment().diff(dateStr, 'years');
}
export function getLikeValue(value: any) {
    return `'%${value}%'`
}

export function getEqualValue(value: any) {
    return `'${value}'`
}

export function getInValue(value: any) {
    var inString: string = ''
    var firstString: string = value[0]
    if (value !== null && value.length > 0) {
        inString = `'${value[0]}'`
        value.filter((id: any) => { return id !== firstString; })
            .forEach((id: string) => inString = inString.concat(`,'${id}'`))
    }
    return inString
}

export function getStringDate(value: String) {
    return `'%${value}.%'`
}

export function getIbanMask(locale: string = 'FRA') {
    switch (locale) {
        case 'FRA':
            return 'FR ## #### #### #### #### XXXX X##'
        case 'MAR':
            return 'MA ## ## ### ############# ##'
        case 'TUN':
            return 'TN ## ## ### ############# ##'
    }
}


export function getBicMask(locale: string = 'FRA') {
    switch (locale) {
        case 'FRA':
            return 'NNNNNNNNXXX'

        default:
            return
    }


}

/**
 * Retrieves the siren mask based on the provided locale.
 *
 * @param locale - Optional parameter representing the locale.
 * @returns The siren mask corresponding to the locale.
 */
export function getSirenMask(locale?: string) {
    switch (locale) {
        case "party-FrenchOrganization":
            return '#########'

        default:
            return
    }
}


export function calculatePercentByValue(value: number, percent?: number): number {
    return percent && percent > 0 ? (value * percent) / 100 : 0;
}
export function formatString(value: string) {
    return value.replace(".", ",")
}
export function formatMask(locale: string, value: number, minDecimal: number) {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: minDecimal }).format(value)
}
export function formatWithMask(value: number, minDecimal: number) {
    return formatMask(i18n.global.t('locale'), value, minDecimal)
}

/**
 * Formats a given amount as a currency using the specified currency and locale.
 * @param {string} currency - The currency code to use for formatting (e.g., "USD", "EUR").
 * @param {number} value - The value to be formatted as currency.
 * @param {string} locale - The locale to use for formatting (e.g., "en-US", "fr-FR").
 * @returns {string} The formatted currency string.
 */
export function formatAmoutswitchCurrency(currency: string, value: number,) {
    return new Intl.NumberFormat(i18n.global.locale.value, { style: 'currency', currency }).format(value);
}
//TODO developer other formats
export function deleteFormatNumber(value: string, locale: string) {
    if (locale === 'fr') {
        return value.toString().trim().replace(/\s/g, '').replace(",", ".")
    } else return value
}

export function deleteFormatMaskAndConvertToNumber(value: string) {
    return parseFloat(deleteFormatNumber(value, i18n.global.t('locale')))
}

export function formatDateToMoment(param: Date | string, separator: string = '/') {
    const dateMomentObject = moment(param, "DD/MM/YYYY");
    const date = dateMomentObject.toDate();
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join(separator)
    );
}


export function keyHandler(evt: any) {
    if (Number(evt.srcElement.value) <= 0 && evt.key === "ArrowDown") {
        evt.preventDefault();
    }
    if (
        evt.key === "-" ||
        evt.key === "." ||
        evt.key === "," ||
        evt.key === "e" ||
        evt.key === "E"
    ) {
        evt.preventDefault();
    }
}

/**
  * Calculates the total of column values for the selected rows
  * @param selectRows - The selected rows
  * @param colName - The column name for which to calculate the total
  * @returns The total of the column values
  */
export function calculTotal(selectRows: any, colName: string) {

    const total = selectRows
        .map((row: any) => row[colName])
        .reduce((acc: number, val: number) => acc + val, 0);
    return isNaN(total) ? "" : total;
}

export function passwordValidator(password: string): string {
    return Masks.passwordMask.test(password) || password === '' ? '' : i18n.global.t("main.connect.rulesPassowrd");
}

export function newPasswordController(userNewPassword: string): string {
    return passwordValidator(userNewPassword);
}

export function confirmPasswordController(confirmPassword: string, userNewPassword: string): string {
    return confirmPassword === userNewPassword || confirmPassword === '' ? '' : i18n.global.t("main.connect.changePasswordMessageFailed");
}

export function required(val: unknown) {
    return !!val || i18n.global.t("main.register.required");
}

export function formatDateToCompleteDate(date: string, mask: string) {
    const payload = moment(date);
    return payload.format(mask);
}

export function checkFormatDate (fieldValue: string) : boolean{
    // Check the format is dd/mm/yyyy or mm/dd/yyyy
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    return regex.test(fieldValue) ? true : false; 
}
export function getGraceTimePeriod (period: any) {
    if (period.unit === 'DAYS'){
        return 'DAYS'
    }
    if (period.unit === 'MONTHS') {
        if (period.duration % 12 === 0) {
            return 'years';
        } else if (period.duration % 6 === 0) {
             return 'semester';
        } else if (period.duration % 3 === 0) {
            return 'quarter';
    }  
    return 'MONTHS';
    }
}

export function beautifyJSONString(jsonString: string) {
    try {
      const jsonObject = JSON.parse(jsonString);
      const beautifiedJSON = JSON.stringify(jsonObject, null, 10);
      return beautifiedJSON;
    } catch (error: any) {
      console.error(`Erreur lors du parsing du JSON: ${error.message}`);
      return jsonString;
    }
}

export function isEmpty(value: any) {
  return value === undefined || value === null || value === "";
}

export function isValidJSON(jsonString: any) {
  try {
      JSON.parse(jsonString);
      return true;
    } catch (error: any) {
      console.error(`JSON not valid: ${error.message}`);
      return false;
    }
}
