
import {Options, Vue} from "vue-class-component";
import {Emit} from "vue-property-decorator";
import store from "@/store";
import i18n from "@/i18n";
import Notification from "@/components/communication/Notification.vue";
import LanguageProfil from "@/components/user-preference/LanguageProfile.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import {Message} from "@/types";
import {settings} from "@/settings";
import {SESSION_TOKEN_KEY} from "@/utils";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import router from "@/router";

const api_query_url = settings.api_query_url;
const systemUid = `odm-contactmechanism`;
const baseUrlGenerated = `${settings.api_url}/${systemUid}/api/1/event/${systemUid}`;
@Options({
  name: "app-header",
  components: {
    Notification,
    LanguageProfil,
  },
  computed: {
    ...mapState("notificationModule", ["notifications"]),
    ...mapGetters("notificationModule", ["getData", "getDataSize"]),
    ...mapState("messagingModule", ["recipients"]),
    ...mapGetters("messagingModule", ["getRecipients"]),
  },
  methods: {
    ...mapActions("notificationModule", ["fetchData"]),
    ...mapActions("messagingModule", ["fetchRecipients"]),

    onClickNotif() {
      /**
       * Gets the list of Recipients options for a selection.
       @returns {Array} List of Recipients options for selection.
       */
      this.fetchRecipients();
    },
  },
})
export default class Header extends Vue {
  message!: Message;
  filterInput = "";
  seeAllBtn = false;
  unreadBtn = false;
  readBtn = false;
  tempData: any;
  getData!: () => Message[];
  getAllUser!: () => void;
  fetchData!: (payload: any) => void;
  fetchRecipients!: () => void;
  getDataSize!: () => number;
  showDetails = false;
  show() {
    this.showDetails = true;
  }

  get listener() {
    return async () => {
      await fetchEventSource(`${baseUrlGenerated}/listener/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(SESSION_TOKEN_KEY)}`,
        },
        async onopen(res) {
          if (res.ok && res.status === 200) {
            console.warn("Connection made ", res);
          } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            console.warn("Client side error ", res);
          }
        },
        onmessage: (event) => {
          if (event.data !== "*|*\n\n") {
            this.fetchData({ ressourceUid: event.data.trim() });
          }
          return true;
        },
        onclose() {
          console.warn("Connection closed by the server");
        },
        onerror(err) {
          console.warn("There was an error from server", err);
        },
      });
    };
  }

  created() {
    this.listener();
    this.loadData();
    console.warn(this.getData);
    /**
     * Gets the list of Recipients options for a selection.
     @returns {Array} List of Recipients options for selection.
     */
    this.fetchRecipients();
    this.getDataSize;
  }
  loadData() {
    this.fetchData(null);
  }

  confirmLogout = false;

  get daaqModel() {
    const userDaaq = store?.state?.authModule?.daaqResourceUid;
    if (userDaaq) {
      return {
        value: userDaaq,
        label: store?.state?.authModule?.daaqLabel,
      };
    }
    return undefined;
  }

  onSelectDAAQ(event: any) {
    store.dispatch("authModule/selectDAAQ", {
      daaq: event.config.daaq,
      daaqResourceUid: event.config.resourceUid,
      daaqLabel: event.config.label,
    });
  }

  @Emit("collapseToggle")
  collapseToggle() { }

  logout() {
    this.confirmLogout = true;
  }
  UserPreference() {
    router.push("/profile");
  }
  get daaqList() {
    return this.user?.daaqList || [];
  }
  get daaqLabel() {
    return this.daaqModel && this.daaqModel.label ? i18n.global.t(`main.daaq.${this.daaqModel.label}`) : "";
  }

  get firstName() {
    return this.user?.firstName;
  }

  get lastName() {
    return this.user?.lastName;
  }

  get user() {
    return store?.state?.authModule?.user;
  }

  get cancelButtonLabel() {
    return i18n.global.t("main.confirmationDialog.cancelButton");
  }

  get confirmationButtonLabel() {
    return i18n.global.t("main.confirmationDialog.confirmationButton");
  }

  /**
   * Getter function to retrieve the value of msgStatusTabs.
   * @returns {Array} array of msgStatusTabs from the store's notificationModule state.
   */
  get msgStatusTabs() {
    return store?.state?.notificationModule?.msgStatusTabs;
  }

  /**
   * Getter function to retrieve the value of selectedstatusTab.
   * @returns {string} The value of msgSelectedStatusTab from the store's notificationModule state.
   */
  get selectedstatusTab() {
    return store?.state?.notificationModule?.msgSelectedStatusTab;
  }

  /**
   * Getter function to retrieve filtered data based on filterInput and selectedstatusTab.
   * @returns {Array<Notification>} Filtered data based on the filterInput value and selectedstatusTab from the store's notificationModule state.
   */
  get filteredData() {
    this.tempData = this.getData;
    const filtered = this.tempData.filter((item: Notification) => {
      return this.filterInput && this.filterInput !== ""
        ? item.subject.resourceUid
          .toLowerCase()
          .includes(this.filterInput.toLowerCase()) ||
        item.metadata
          .find((item: any) => item.key === "Entity_Reference")
          .value.includes(this.filterInput) ||
        item.sender.toLowerCase().includes(this.filterInput.toLowerCase())
        : this.getData;
    });
    filtered.sort((a:any, b:any) => {
      return b.communicationDate - a.communicationDate;
    });
    return filtered.filter((item: Notification) => {
      return this.selectedstatusTab === "ALL"
        ? filtered
        : item.status.resourceUid === this.selectedstatusTab;
    });
  }

  sortNotificationList(type?: string) {
    this.tempData.sort((a: Notification, b: Notification) => {
      switch (type) {
        case "unread":
          if (a.status.resourceUid < b.status.resourceUid) {
            return -1;
          }
          if (a.status.resourceUid > b.status.resourceUid) {
            return 1;
          }
          return 0;
        case "importance":
          return b.priority - a.priority;
        default:
          return (
            new Date(b.communicationDate).getTime() -
            new Date(a.communicationDate).getTime()
          );
      }
    });
  }

  onClickLogoutButton() {
    this.$store.dispatch("authModule/signOut");
  }

  /**
   * Handles the event when the status tab is changed and updates the selected status tab.
   * @param {string} selectedStatus - The selected status value to update.
   */
  onStatusTabChanged(selectedStatus: string) {
    this.$store.dispatch("notificationModule/updateSelectedStatusTab", selectedStatus);
  }
}
