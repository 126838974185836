import {Order, Task, TaskExecuteAction, TaskModule, TaskState} from "@/types";
import {executeTask} from "@/store/services/task/workflow";
import {deepClone} from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";

export async function executeStartOrder(taskState: TaskState, order: Order, task: Task) {
    const startOrder = deepClone(taskState.startOrderModule.bind)
    const {
        decisionDate,
        comment,
        decision,
        externalReference
    } = startOrder
    // TODO verify if need to save the decision on the database
    const taskExecuteAction: TaskExecuteAction = {
        taskId: task.taskId || '',
        variables: {
            orderStatus: { value: decision === 'start' ? 'ORDER_STARTED' : 'ORDER_REJECTED', type: "String" },
            comment: {
                type: "String",
                value: comment
            },
            orderExternalReference : {
              type: "String",
              value: externalReference
            }
        }
    }
    try {
        const result = await executeTask(taskExecuteAction);
        Notify.create({
            message: i18n.global.t('task.executed'),
            color: 'positive'
        });
        return result;
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}
